import { email, firstName, lastName, mobile } from '@/validations/components'

export default {
  tiny: {
    email
  },
  normal: {
    email,
    firstName,
    lastName,
    mobile
  }
}
