export default function useDocument() {
  // METHODS
  function setStaticBody() {
    // set fixed position on body (scrollY is 0 after setting position)
    const scrollY = window.scrollY
    document.body.style.position = 'fixed'
    document.body.style.top = `-${scrollY}px`
    document.body.style.left = '0'
    document.body.style.right = '0'
  }

  function unsetStaticBody() {
    if (document.body.style.position === 'fixed') {
      const scrollY = document.body.style.top

      document.body.style.removeProperty('position')
      document.body.style.removeProperty('top')
      document.body.style.removeProperty('left')
      document.body.style.removeProperty('right')

      window.scrollTo(0, Math.abs(parseInt(scrollY || '0', 10)))
    }
  }

  return {
    setStaticBody,
    unsetStaticBody,
  }
}
