<template>
  <!-- prettier-ignore -->
  <the-form-modal
    v-model="open"
    full-screen
    name="medicalOfficeSearchModal"
    no-margin
    x-only
  >
    <medical-office-maps
      :contract-start-date="contractStartDate"
      :options="searchOptions"
      :product="selectedProduct"
      @close="handleCloseEvent"
      @submit="updateDoctor"
    />
  </the-form-modal>
</template>

<script setup>
import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue'

import get from 'lodash/get'

import { events$, loading$ } from '@/services'
import { logError } from '@/helpers/Logger'

import masterDataAPI from '@/api/masterData'
import basketStore from '@/store/basket'
import useBasket from '@/hooks/useBasket'
import useBrowser from '@/hooks/useBrowser'
import useI18n from '@/hooks/useI18n'
import useMapSearch from '@/hooks/useMapSearch'
import useMedicalOffice from '@/hooks/useMedicalOffice'
import usePersonSpecificProduct from '@/hooks/usePersonSpecificProduct'
import useProduct from '@/hooks/useProduct'
import useProductPersons from '@/hooks/useProductPersons'

import MedicalOfficeMaps from '@/components/MedicalSearch/components/MedicalOfficeMaps'
import { productAVMtypes, ZOOM_DEFAULT } from '@/components/MedicalSearch/config/constants'
import { transformDoctorForProduct } from '@/components/MedicalSearch/utils/product'
import { appliesToProduct } from '@/utils/Doctor'

import { EVENT_MEDICAL_OFFICE_MODAL_OPEN, EVENT_PRODUCT, EVENT_SHOW_PRODUCT_SUGGESTION, SOURCE } from '@/config/events'

// HOOKS
const { contractData } = useBasket()
const { browser } = useBrowser()
const { t } = useI18n()
const { onZoom, setInitialZoom } = useMapSearch()
const { selectProductOption } = usePersonSpecificProduct()
const { getCategoryIdFromProduct, getGroupFromProduct } = useProduct()
const { addProductDoctorToBasket, addProductToOvpBasket } = useProductPersons()

// INIT
defineEmits(['closed'])

// DATA
const closingCallback = ref(() => {})
const isProductSwapDisallowed = ref(false)
const open = ref(false)
const selectedPersonId = ref(null)
const selectedProduct = ref({})
const selectedSource = ref(SOURCE.CONFIGURATOR)

// COMPUTED
const contractStartDate = computed(() => {
  return contractData.value.contractStartDate
})

const initialZoom = computed(() => {
  const _zoom = labelSuffix.value === 'medbase' ? 11 : ZOOM_DEFAULT
  setInitialZoom(_zoom)
  return _zoom
})

const labelSuffix = computed(() => {
  const type = get(productAVMtypes, selectedProduct.value.productId, '')
  return type ? type.toLowerCase() : 'default'
})

/**
 * Will initialize the labels for the medical-office-search itself
 * @return {Object}
 */
const labels = computed(() => {
  return {
    sanitas: {
      postAddress: t('application.sanitas.postAddress'),
      phoneNumber: t('application.sanitas.phoneNumber'),
    },
    form: {
      input: t('medicalofficesearch.form.input')[labelSuffix.value],
      title: t('medicalofficesearch.form.title')[labelSuffix.value],
      submit: t('medicalofficesearch.form.submit')[labelSuffix.value],
      zipCode: {
        title: t('person.plz'),
        placeholder: t('location.placeholder'),
      },
      errors: {
        keyRequired: t('form.error.required'),
        noResults: t('location.noresults'),
        serviceNotAvailable: t('error.NETWORK'),
      },
      filters: {
        title: t('medicalofficesearch.filters.title'),
        patients: t('medicalofficesearch.filters.patients'),
        name: t('medicalofficesearch.filters.name')[labelSuffix.value],
      },
    },
    type: t('medicalofficesearch.type'),
    states: t('medicalofficesearch.states'),
    toggle: {
      map: t('medicalofficesearch.toggle.card'),
      list: t('medicalofficesearch.toggle.list'),
    },
    messages: {
      emptyResults: {
        text: t('medicalofficesearch.messages.emptyResults.text')[labelSuffix.value],
        link: t('medicalofficesearch.messages.emptyResults.link'),
      },
      noResults: t('medicalofficesearch.messages.noResults')[labelSuffix.value],
      moreResults: t('medicalofficesearch.messages.moreResults'),
      confirm: {
        office: {
          ...t('medicalofficesearch.messages.office'),
          title: t('medicalofficesearch.messages.office.title')[labelSuffix.value],
          description: t('medicalofficesearch.messages.office.description')[labelSuffix.value],
        },
        product: t('medicalofficesearch.messages.product'),
      },
    },
    reductions: {
      reduction: t('product.reduction'),
      title: t('medicalofficesearch.reductions.title'),
      text: t('medicalofficesearch.reductions.text'),
      note: t('medicalofficesearch.reductions.note'),
      level: t('medicalofficesearch.reductions.level'),
    },
  }
})

const searchOptions = computed(() => {
  return {
    filterableProducts: false,
    location: contractData.value.address,
  }
})

const viewPort = computed(() => {
  return browser.viewportLabel
})

// METHODS
function doOpenEvent() {
  open.value = true
}

async function getSimulatedProductPrice(personId, productId, selectedOption, doctor) {
  let tmpBasket = await addProductToOvpBasket(personId, productId, { doctor })
  if (selectedOption !== false) {
    tmpBasket = await selectProductOption({ personId, productId, option: selectedOption, __basket: tmpBasket })
  }
  tmpBasket = await basketStore.updateOvpBasket(tmpBasket, { simulate: true })

  return tmpBasket.persons
    .find(__person => __person.personId === personId)
    .products.products[productId].prices.find(p => p.selected).price
}

function handleBeforeOpen(event) {
  const { getProduct } = usePersonSpecificProduct()
  const { setOptions } = useMedicalOffice()

  const { personId, product, source } = event
  selectedPersonId.value = personId
  selectedProduct.value = product
  selectedSource.value = source

  setOptions({
    labels: labels.value,
    viewPort: viewPort.value,
    callbacks: {
      getProduct: productId => getProduct(personId, productId),
      getLocations: payload => masterDataAPI.getLocations(payload),
      findProviders: payload => masterDataAPI.findProviders(payload),
    },
  })
}

function handleCloseEvent() {
  open.value = false
}

/**
 * update ui-doctor and add product to basket
 * @param  {Object} data
 */
async function updateDoctor(data) {
  loading$.start()

  const doctor = transformDoctorForProduct(data)
  const categoryId = getCategoryIdFromProduct(selectedProduct.value.productId)
  const group = getGroupFromProduct(selectedProduct.value.productId)

  // do simulate call for both product, with doctor already added
  const selectedOption = selectedProduct.value.prices.find(p => p.selected)
  const simulatedPrice = await getSimulatedProductPrice(
    selectedPersonId.value,
    selectedProduct.value.productId,
    selectedOption,
    doctor
  )

  // check, if the doc is available for other products of the same group and if they're actually cheaper
  let tip = false
  if (group.products.length > 1 && !isProductSwapDisallowed.value) {
    const otherProductId = group.products.find(productId => productId !== selectedProduct.value.productId)
    if (otherProductId && appliesToProduct(otherProductId, doctor)) {
      const otherProductPrice = await getSimulatedProductPrice(selectedPersonId.value, otherProductId, false, doctor)

      if (simulatedPrice > otherProductPrice) {
        tip = otherProductId
      }
    }
  }

  if (tip !== false) {
    open.value = false
    loading$.end()

    return events$.emit(EVENT_SHOW_PRODUCT_SUGGESTION, {
      personId: selectedPersonId.value,
      categoryId,
      groupId: group.id,
      product: selectedProduct.value,
      doctor,
      source: selectedSource.value,
    })
  } else {
    return addProductDoctorToBasket(selectedPersonId.value, selectedProduct.value.productId, doctor)
      .then(basket => basketStore.updateOvpBasket(basket))
      .then(basket => {
        events$.emit(EVENT_PRODUCT.ADDED, {
          basket,
          personId: selectedPersonId.value,
          categoryId,
          productId: selectedProduct.value.productId,
          source: selectedSource.value,
        })
        open.value = false
        return basket
      })
      .catch(error => {
        loading$.failed()
        logError(error)
      })
      .finally(() => {
        loading$.end()
      })
  }
}

// WATCHERS
watch(
  () => initialZoom.value,
  value => {
    onZoom(value)
  }
)

// LIFECYCLE HOOKS
onBeforeMount(() => {
  events$.on(EVENT_MEDICAL_OFFICE_MODAL_OPEN, event => {
    handleBeforeOpen(event)
    doOpenEvent()
    if (event.callback) closingCallback.value = event.callback
    if (event.isProductSwapDisallowed) isProductSwapDisallowed.value = event.isProductSwapDisallowed
  })
})
onBeforeUnmount(() => {
  events$.off(EVENT_MEDICAL_OFFICE_MODAL_OPEN, doOpenEvent)
})
</script>
