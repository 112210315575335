import {
  MapIcon,
  MapIconActive,
  MapIconCluster,
  MapIconClusterHover,
  MapIconHover,
  SanitasLogo,
} from '@/components/MedicalSearch/config/icons'
import { createPosition } from '@/components/MedicalSearch/utils/map'

export const googleIconStates = {
  NORMAL: 'NORMAL',
  HOVER: 'HOVER',
  ACTIVE: 'ACTIVE',
  CLUSTER: 'CLUSTER',
  CLUSTER_HOVER: 'CLUSTER_HOVER',
}

const SANITAS_MARKER = {
  id: 'SANITAS',
  address: 'sanitas.postAddress',
  phoneNumber: 'sanitas.phoneNumber',
  minZoom: 16,
  position: {
    lat: 47.377423,
    lng: 8.531953,
  },
}

/**
 * Creates a cluster marker which clusters multiple marker under one marker
 */
export const createClusterMarker = google => {
  const width = 45
  const height = 45

  return {
    styles: [
      {
        width,
        height,
        textSize: 14,
        textColor: 'rgb(19, 19, 19)',
        scaledSize: scaledSize(google, width, height),
        url: getMarkerIcon(googleIconStates.CLUSTER),
        className: 'cluster',
      },
      {
        width,
        height,
        textSize: 14,
        textColor: 'rgb(91, 172, 38)',
        scaledSize: scaledSize(google, width, height),
        url: getMarkerIcon(googleIconStates.CLUSTER_HOVER),
        className: 'cluster-hover',
      },
      {
        width,
        height,
        textSize: 14,
        textColor: 'rgb(255, 255, 255)',
        scaledSize: scaledSize(google, width, height),
        url: getMarkerIcon(googleIconStates.ACTIVE),
        className: 'cluster-active',
      },
    ],
  }
}

export const createMarker = (google, options) => {
  const { id, geo, state, style, ...marker } = options
  const icon = createMarkerIcon(google, { state, height: 40, width: 40 })

  return {
    ...marker,
    id,
    options: {
      id,
      position: createPosition(geo),
      className: 'marker',
      icon,
      ...style,
    },
  }
}

/**
 * Creates a custom HTML element displayed on the map when hovered over a marker
 *
 */
export const createMarkerHoverCard = marker => {
  if (!marker) return null
  const offsetY = marker.id ? -70 : -60

  return {
    offsetY: offsetY,
    position: marker.options.position,
    data: marker,
  }
}

export const createMarkerIcon = (google, options) => {
  const { height, width } = options
  const state = options.state ? options.state : options.icon
  const icon = getMarkerIcon(state)
  return {
    url: icon,
    scaledSize: scaledSize(google, height, width),
  }
}

/**
 * Creates a custom marker with the Sanitas logo
 */
export const createSanitasMarker = google => {
  const { id, position } = SANITAS_MARKER
  return {
    ...SANITAS_MARKER,
    options: {
      id,
      position,
      className: 'sanitas-marker',
      icon: createMarkerIcon(google, { state: id, height: 75, width: 75 }),
    },
  }
}

/**
 * Creates markers for the map depending on the given offices.
 * Styles the marker regarding if it's the hovered, the selected or a default marker
 */
export const drawMarkers = (data, google, hoveredId, selectedId) => {
  return data.map(entry => {
    const state = markerIconState(entry.zsrNumber, hoveredId, selectedId)
    return createMarker(google, { ...entry, state })
  })
}

/**
 * Searches for the marker object with the given id.
 */
export const findMarkerById = (markers, id) => {
  return markers.find(d => d.zsrNumber === id) || null
}

const markerIconState = (zsrNumber, hovered, selected) => {
  if (zsrNumber === selected) return googleIconStates.ACTIVE
  if (zsrNumber === hovered) return googleIconStates.HOVER
  return googleIconStates.NORMAL
}

const scaledSize = (google, width, height) => {
  return new google.Size(width, height)
}

const getMarkerIcon = state => {
  switch (state) {
    case googleIconStates.ACTIVE:
      return MapIconActive
    case googleIconStates.HOVER:
      return MapIconHover
    case googleIconStates.NORMAL:
      return MapIcon
    case googleIconStates.CLUSTER:
      return MapIconCluster
    case googleIconStates.CLUSTER_HOVER:
      return MapIconClusterHover
    case SANITAS_MARKER.id:
      return SanitasLogo
    default:
      return state || ''
  }
}
