<template>
  <!-- prettier-ignore -->
  <div
    v-if="isActive"
    ref="avatarRef"
    class="avatar"
    :class="classes"
  >
    <div class="avatar-image">
      <img
        alt="avatar"
        class="avatar__image"
        :src="src"
      />
    </div>
    <div class="avatar-dialog">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'

import useBrowser from '@/hooks/useBrowser'

import AlvaDesktopHappy from '@/assets/alva/Alva_Desktop_froehlich_00.06.svg'

// HOOKS
const { browser } = useBrowser()

// INIT
const MOODS = { happy: AlvaDesktopHappy }
const props = defineProps({
  mood: {
    type: String,
    default: 'happy',
  },
  noDesktop: {
    type: Boolean,
    default: false,
  },
  noMobile: {
    type: Boolean,
    default: false,
  },
  position: {
    type: Object,
    default: () => {},
  },
  small: {
    type: Boolean,
    default: false,
  },
  tiny: {
    type: Boolean,
    default: false,
  },
})

// DATA
const avatarRef = ref(undefined)

// COMPUTED
const classes = computed(() => {
  return {
    'avatar--small': props.small,
    'avatar--tiny': props.tiny,
  }
})

const isActive = computed(() => {
  const isDesktop = browser.isDesktop

  return (isDesktop && !props.noDesktop) || (!isDesktop && !props.noMobile)
})

const src = computed(() => {
  return MOODS[props.mood]
})

// LIFECYCLE HOOKS
onMounted(() => {
  if (props.position) {
    avatarRef.value.style.marginTop = props.position.top + 'px'
  }
})
</script>

<style name="mobile" scoped>
.avatar {
  text-align: center;

  &-image {
    position: relative;
    height: 112px;
    width: 100px;
    margin: 0 auto;
  }

  &__image {
    height: 112px;
    width: 100px;
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.75s ease-in-out;
    z-index: 10;

    &--small {
      height: 55px;
      width: 50px;
    }

    &--tiny {
      height: 45px;
      width: 40px;
    }
  }

  &--small {
    .avatar {
      &-image,
      &__image {
        height: 55px;
        width: 50px;
      }
    }
  }

  &--tiny {
    .avatar {
      &-image,
      &__image {
        height: 40px;
        width: 35px;
      }
    }
  }
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .avatar {
    text-align: initial;
  }

  .avatar-image {
    margin: initial;
  }
}
</style>
