<template>
  <!-- prettier-ignore -->
  <div
    ref="el"
    class="results-card"
    :class="classes"
    :data-office-number="$props.office.zsrNumber"
    @click="select"
    @mouseover="handleMouseOver"
    @mouseout="handleMouseOut"
  >
    <div class="results-card__content">
      <div
        class="results-card__name"
        v-text="name"
      />
      <div
        class="results-card__address"
        v-html="`${$props.office.street} ${$props.office.houseNumber}<br/>${$props.office.zipCode} ${$props.office.town}`"
      />
      <div class="results-card__availability">
        <div
          v-if="isDoctorAvailable($props.office)"
          class="results-card__available"
        >
          <the-icon
            art="light"
            name="circle-check"
          />
          <the-typography
            tag="span"
            type="caption"
            v-text="$t('medicalofficesearch.states.all')"
          />
        </div>
        <div
          v-else-if="$props.office.medicalOfficeStatus === medState.ACCORDING_FAMILY_DOCTOR"
          class="results-card__arrangement"
        >
          <the-icon
            art="light"
            name="exclamation-circle"
          />
          <the-typography
            tag="span"
            type="caption"
            v-text="$t('medicalofficesearch.states.arrangement')"
          />
        </div>
        <div
          v-else
          class="results-card__arrangement results-card__arrangement--negative"
        >
          <the-icon
            art="light"
            name="ban"
          />
          <the-typography
            tag="span"
            type="caption"
            v-text="$t(`medicalofficesearch.messages.office.title.${getAVMId(props.product?.productId).toLowerCase()}`)"
          />
        </div>
      </div>
    </div>

    <template v-if="discountGroup">
      <div class="doctor-discount">
        <div v-show="false">
          <div :id="`discountGroup_${$props.product.productId}`">
            <h4 v-text="$t('medicalofficesearch.reductions.title')" />
            <div v-text="$t('medicalofficesearch.reductions.text')" />
            <div>
              <div>
                <the-icon
                  class="doctor__icon"
                  art="solid"
                  name="signal-good"
                />
                {{ $t('medicalofficesearch.reductions.level.highest') }}
              </div>
              <div>
                <the-icon
                  class="doctor__icon"
                  art="solid"
                  name="signal-fair"
                />
                {{ $t('medicalofficesearch.reductions.level.medium') }}
              </div>
              <div>
                <the-icon
                  class="doctor__icon"
                  art="solid"
                  name="signal-weak"
                />
                {{ $t('medicalofficesearch.reductions.level.lowest') }}
              </div>
            </div>
            <br>
            <div v-html="$t('medicalofficesearch.reductions.note')" />
            <br>
          </div>
        </div>

        <div
          v-if="!isEmpty(discountTooltip)"
          class="doctor-discount__title"
        >
          <span v-tooltip="discountTooltip">
            {{ $t('product.reduction') }}
            <the-icon
              class="doctor__icon"
              art="solid"
              :name="discountIcon"
            />
          </span>
        </div>
      </div>
    </template>

    <template v-if="officeState">
      <p
        v-if="isMobile"
        class="results-card__state"
        v-text="getTranslation(officeState)"
      />
      <the-icon
        v-else
        v-tooltip="getTranslation(officeState)"
        class="results-card__icon"
        art="light"
        name="circle-info"
      />
    </template>
  </div>
</template>

<script setup>
import { computed, nextTick, reactive, ref, watch } from 'vue'

import isEmpty from 'lodash/isEmpty'

import useMapSearch from '@/hooks/useMapSearch'
import useMedicalOffice from '@/hooks/useMedicalOffice'
import useBrowser from '@/hooks/useBrowser'

import { AVMGroupType, getAVMId } from '@/components/MedicalSearch/utils/product'
import { isDoctorAvailable, getMedState } from '@/components/MedicalSearch/utils/doctor'

import { medState } from '@/components/MedicalSearch/config/constants'

// HOOKS
const { browser } = useBrowser()
const { setHoveredId, setSelectedId } = useMapSearch()
const { getTranslation } = useMedicalOffice()

// INIT
const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  autoScroll: {
    type: Boolean,
    default: false,
  },
  hover: {
    type: Boolean,
    default: false,
  },
  hoverDelay: {
    type: Number,
    default: 300,
  },
  office: {
    type: Object,
    default: () => undefined,
    required: true,
  },
  product: {
    type: Object,
    default: () => undefined,
  },
})

// DATA
const isMobile = !browser.isDesktop
const timeout = ref(null)
const el = ref(null)
let discountTooltip = reactive({})

// COMPUTED
const classes = computed(() => {
  return {
    'results-card--active': props.active,
    'results-card--hover': props.hover && !props.active,
  }
})

const discountGroup = computed(() => {
  // discounts don't apply
  if (!props.product?.showDiscountGroup) return false

  const productNetwork = getAVMId(props.product?.productId)
  if (productNetwork === AVMGroupType.NET_MED) {
    const groups = props.office.medicalOfficeNets.filter(
      n => n.avmDefinition === AVMGroupType.NET_MED && n.discountGroup
    )
    return groups && groups.length === 1 ? groups[0] : false
  }
  return false
})

const discountIcon = computed(() => {
  switch (parseInt(discountGroup.value.discountGroup)) {
    case 1:
    default:
      return 'signal-good'

    case 2:
      return 'signal-fair'

    case 3:
      return 'signal-weak'
  }
})

const name = computed(() => {
  return [props.office.lastName, props.office.firstName].join(' ')
})

const officeState = computed(() => {
  return getMedState(props.office.medicalOfficeStatus)
})

// METHODS
function getDiscountContent() {
  const __el = document.getElementById(`discountGroup_${props.product.productId}`)
  if (__el) {
    Object.assign(discountTooltip, {
      allowHTML: true,
      content: __el,
    })
  } else {
    window.setTimeout(() => {
      getDiscountContent()
    }, 1000)
  }
}

function handleMouseOut() {
  clearTimeout(timeout.value)
  if (props.hover) {
    setHoveredId()
  }
}

function handleMouseOver() {
  timeout.value = setTimeout(() => {
    setHoveredId(props.office.zsrNumber)
  }, props.hoverDelay)
}

function select() {
  setSelectedId(props.office.zsrNumber)
}

function scrollIntoView() {
  if (!props.autoScroll || !props.active) {
    return
  }

  nextTick(() => {
    if (!el.value) {
      return
    }

    const offsetTop = el.value.getBoundingClientRect().top
    const offsetBottom = el.value.offsetHeight
    const parentElement = el.value?.parentElement
    const parentNode = parentElement?.parentNode

    if (offsetTop < 0 || offsetTop > parentNode.offsetHeight - offsetBottom) {
      el.value.scrollIntoView({
        behavior: 'instant',
        block: 'nearest',
      })
    }
  })
}

// WATCHERS
watch(() => [props.active], scrollIntoView)
watch(
  () => discountGroup,
  value => {
    if (value) getDiscountContent()
  },
  { immediate: true }
)
</script>

<style scoped>
.results-card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--c-primary-neutral-1);
  padding: 10px 40px 10px 15px;
  color: var(--c-primary-neutral-1);

  cursor: pointer;
  user-select: none;

  transition: all 0.25s ease-in-out;
}

.results-card--active.results-card {
  color: var(--c-primary-neutral-1);
  background-color: var(--c-primary-color-2-d);
}

.results-card__availability {
  padding: 4px 0 0;
}

.results-card__available {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--c-primary-color-2);

  .icon {
    font-size: 20px;
    color: var(--c-primary-color-2);
  }
}

.results-card__arrangement {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--c-secondary-color-3);

  .icon {
    font-size: 20px;
    color: var(--c-secondary-color-3);
  }

  &--negative,
  &--negative .icon {
    color: var(--c-secondary-color-1);
  }
}

.results-card__name {
  font-weight: bold;
}

.results-card__types {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.results-card__type {
  display: inline-flex;
  margin-top: 5px;
  margin-right: 5px;

  .icon {
    margin-right: 5px;
  }
}

.results-card__state {
  margin-top: 10px;
}

.results-card__icon {
  position: absolute;
  top: 10px;
  right: 20px;
}

.doctor__icon {
  font-size: 25px;
  color: var(--c-primary-color-2);
  margin: 0 0 2px 0;
}

@media (--md) {
  .results-card {
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px 10px 30px;

    &:hover,
    &--hover {
      background-color: var(--c-primary-color-2-d);
      .icon {
        fill: var(--c-primary-color-3);
      }
    }
  }

  .results-card__types {
    height: 100%;
    min-height: 60px;
    justify-content: space-between;
  }

  .results-card__type {
    width: 25px;
    height: 100%;
    align-items: center;
    display: flex;

    &:not(:first-child) {
      margin-left: 20px;
    }
  }

  .results-card__icon {
    top: inherit;
    bottom: 10px;
  }
}
</style>
