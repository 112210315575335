<template>
  <!-- prettier-ignore -->
  <transition
    mode="out-in"
    :name="$props.showDetails ? 'slide-down' : 'slide-up'"
    @after-enter="afterEnter"
    @enter="enter"
    @leave="leave">
    <div v-if="$props.showDetails">
      <basket-widget-person
        v-for="person in $props.persons"
        :key="person.personId"
        class="basket-person"
        :person="person"
        :readonly="$props.readonly"
      />
    </div>
  </transition>
</template>

<script setup>
import useHeightTransition from '@/directives/HeightTransition'

import BasketWidgetPerson from '@/components/BasketWidget/Container/Person'

// HOOKS
const { afterEnter, enter, leave } = useHeightTransition()

// INIT
defineProps({
  persons: {
    type: Array,
    required: true,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  showDetails: {
    type: Boolean,
    default: false,
  },
})
</script>

<style name="animations" scoped>
.slide-down-enter-active,
.slide-down-leave-active,
.slide-up-enter-active,
.slide-up-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-down-enter,
.slide-up-leave-active,
.slide-down-leave-active,
.slide-up-enter {
  opacity: 0;
  height: 0;
  transform: translate(0, -2em);
}
</style>
