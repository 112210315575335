<template>
  <!-- prettier-ignore -->
  <div class="basket">
    <a-b-config />

    <the-icon
      v-if="!isReadyWithPartner"
      class="fa-spin"
      art="light"
      name="spinner-third"
    />
    <template v-else>
      <button
        id="basketWidgetToggle"
        class="basket-toggle"
        :class="{
          'basket-toggle--active': open,
          'basket-toggle--www': isWww
        }"
        plain
        @click="toggle"
      >
        <the-icon
          class="basket-toggle__cart"
          art="light"
          name="cart-shopping"
        />

        <div
          v-if="products > 0"
          class="basket-toggle__indicator"
          v-html="products"
        />
      </button>

      <basket-widget-overlay
        :is-manually-opened="manuallyOpened"
        :is-open="isOpen"
        :message="message"
        :persons="editablePersons"
        :readonly="isReadonly"
        :show-details="showDetails"
        :total="basketTotal"
        @action-click="gotoBasket"
        @close="toggle"
        @toggle-details="toggleDetails"
      />

      <the-modal
        v-slot="{ modalProps }"
        v-model="showBasketScreenSaveModal"
        name="basketSaveModal"
        x-only
        @close="saveModalSource = ''"
      >
        <basket-save-modal
          :source="saveModalSource"
          @canceled="modalProps.cancel"
          @saved="modalProps.submit"
        />
      </the-modal>
    </template>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { events$, loading$ } from '@/services'

import masterDataAPI from '@/api/masterData'
import basketStore from '@/store/basket'
import useApplication from '@/hooks/useApplication'
import useBasket from '@/hooks/useBasket'
import useI18n from '@/hooks/useI18n'
import usePersonDetails from '@/hooks/usePersonDetails'
import useStatus from '@/hooks/useStatus'

import ABConfig from '@/components/Container/ABConfig'
import BasketSaveModal from '@/components/BasketWidget/Modals/BasketSaveModal'
import BasketWidgetOverlay from '@/components/BasketWidget/Container/Overlay'

import {
  EVENT_BASKET,
  EVENT_DOTCOM_SURVEY_WEBSITE,
  EVENT_OVP,
  EVENT_PRODUCT,
  EVENT_TRACKING,
  EVENT_URL,
  SOURCE,
} from '@/config/events'
import { CHANNEL, ROUTE_NAME } from '@/config/constants'

// HOOKS
const { isReadyWithPartner, isWww } = useApplication()
const { createDefaultBasket } = useBasket()
const { t } = useI18n()
const { basketTotal, personsWithDetails } = usePersonDetails()
const { basketHasProducts } = useStatus()

// INIT
let route, router
if (!isWww.value) {
  route = useRoute()
  router = useRouter()
}
const props = defineProps({
  options: {
    type: Object,
    required: true,
  },
})

// DATA
const init = ref(false) // used to delay the initial basketTotal-watch cycle, otherwise the widget initially opens on dotcom
const manuallyOpened = ref(false)
const message = ref(null)
const open = ref(false)
const saveModalSource = ref('')
const showBasketScreenSaveModal = ref(false)
const showDetails = ref(false)

// COMPUTED
const editablePersons = computed(() => {
  return personsWithDetails.value.filter(p => p.contractModified)
})

const hasProducts = computed(() => {
  return basketHasProducts(personsWithDetails.value)
})

const isBasketScreen = computed(() => {
  return route && route.name === ROUTE_NAME.BASKET
})

const isOpen = computed(() => {
  return open.value
})

const isReadonly = computed(() => {
  return props.options.readonly || basketStore.basket.existingCustomer
})

const products = computed(() => {
  return personsWithDetails.value.reduce((acc, value) => {
    acc += value.selectedProducts.length
    return acc
  }, 0)
})

// METHODS
async function createBasketFromParams(data) {
  try {
    loading$.start()

    const payload = {
      existingCustomer: false,
      persons: [
        {
          personData: {
            dateOfBirth: data.dateOfBirth,
            firstName: data.firstName,
            gender: data.gender,
          },
          contractModified: true,
        },
      ],
      address: {
        primeRegion: data.primeRegion,
        locationNumber: data.locationNumber,
        zipCode: data.zipCode,
        zipCodeAddon: data.zipCodeAddon,
      },
    }

    const defaultBasket = await createDefaultBasket(payload)

    const locations = await masterDataAPI.getLocations({
      query: payload.address.zipCode,
      contractStartDate: defaultBasket.contractStartDate,
    })

    const location = locations.find(l => {
      return (
        l.primeRegion === payload.address.primeRegion &&
        l.locationNumber === payload.address.locationNumber &&
        l.zipCode === payload.address.zipCode &&
        l.zipCodeAddon === payload.address.zipCodeAddon
      )
    })

    defaultBasket.address = location

    await basketStore.createOvpBasket(defaultBasket)

    events$.emit(EVENT_OVP.BASKET_PREFILLED)

    loading$.end()
  } catch (error) {
    loading$.failed()
    console.error(error)
  }
}

function gotoBasket() {
  // in dvp
  if (route?.name) {
    if (isBasketScreen.value) {
      open.value = false
      return
    }

    router.push({ name: ROUTE_NAME.BASKET })

    // on dotcom
  } else {
    let url = props.options.configuratorUrl
    if (hasProducts.value) {
      if (basketStore.basket.channel === CHANNEL.PRICEWIDGET) {
        events$.emit(EVENT_TRACKING.ENTERED, { source: CHANNEL.PRICEWIDGET })
      }
      if (basketStore.basket.channel === CHANNEL.PRODUCTWIDGET) {
        events$.emit(EVENT_TRACKING.ENTERED, { source: CHANNEL.PRODUCTWIDGET })
      }
      url += '#/basket'
    }
    window.location.href = url
  }
}

function toggle() {
  open.value = manuallyOpened.value = !open.value

  if (!open.value) {
    message.value = null

    if (isWww.value) {
      events$.emit(EVENT_DOTCOM_SURVEY_WEBSITE)
    }
  }
}

function toggleDetails() {
  showDetails.value = !showDetails.value
  manuallyOpened.value = true
}

function initListeners() {
  const { personsDetails } = usePersonDetails()

  window.setTimeout(() => {
    init.value = true
  }, 2000 /*magic-numbers for the win*/)

  events$.on(EVENT_PRODUCT.ADDED, event => {
    const { personId, productId } = event
    if (personId && productId) {
      const firstName = personsDetails.value[personId].firstName
      const product = t(`content.products.${productId}.name`)
      message.value = t('basket.message', { product, firstName })
    }
  })

  events$.on(EVENT_PRODUCT.REMOVED, event => {
    const isNotLastProductAndDeletedWithinWidget = hasProducts.value && event?.source === SOURCE.BASKET_WIDGET
    showDetails.value = isNotLastProductAndDeletedWithinWidget
    open.value = !isBasketScreen.value
    manuallyOpened.value = isNotLastProductAndDeletedWithinWidget
    message.value = null
  })

  events$.on([EVENT_PRODUCT.ADDED, EVENT_PRODUCT.CHANGED], _event => {
    if (hasProducts.value) {
      showDetails.value = false
      open.value = !isBasketScreen.value
      manuallyOpened.value = false
    }
  })

  events$.on(EVENT_BASKET.OPEN, _event => {
    showDetails.value = true
    open.value = true
    manuallyOpened.value = false
  })

  events$.on(EVENT_BASKET.CLOSE, () => {
    open.value = false
  })

  events$.on(EVENT_BASKET.OPEN_SCREEN_SAVE_MODAL, data => {
    saveModalSource.value = data.source

    if (data.source === SOURCE.BASKET) {
      showBasketScreenSaveModal.value = true
    } else if (data.source === SOURCE.AIDE_BASKET) {
      // do nothing, handled in Aide
    } else {
      throw new Error(`EVENT_BASKET.OPEN_SCREEN_SAVE_MODAL unknown source ${JSON.stringify(data)}`)
    }
  })

  events$.once(EVENT_URL.PREFILL, data => {
    createBasketFromParams(data)
  })
}

// WATCHERS
watch(basketTotal, (newPrice, oldPrice) => {
  if (init.value && oldPrice !== newPrice && route?.name !== ROUTE_NAME.BASKET) {
    open.value = true
  }
})

watch(open, newValue => {
  if (!newValue && hasProducts.value) {
    events$.emit(EVENT_BASKET.WIDGET_CLOSE)
  }
})

// LIFECYCLE HOOKS
onMounted(initListeners)
</script>

<style name="mobile" scoped>
.basket {
  position: relative;
  font-family:
    Open Sans,
    sans-serif;
}

.basket-toggle {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  position: relative;
}

.basket-toggle__cart {
  font-size: 16px;
  color: var(--c-primary-neutral-1);
}

.basket-toggle__indicator {
  position: absolute;
  left: 20px;
  border-radius: 8px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  background-color: var(--c-primary-color-3);
  color: var(--c-primary-neutral-3);
  opacity: 0.8;
  font-size: 10px;
  font-weight: 700;
  top: 0px;
}

.basket-toggle:hover,
.basket-toggle--active {
  .basket-toggle__cart {
    color: var(--c-primary-color-2);
  }
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .basket-toggle {
    margin: 0;
  }

  .basket-toggle--www .basket-toggle__cart {
    font-size: 24px;
  }
}
</style>
