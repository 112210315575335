import { computed } from 'vue'
import { cloneDeep } from 'lodash'

import { addDoctorToBasket, addDoctorToProduct, removeUnusedDoctors } from '@/utils/Doctor'
import { updatePersonInBasket } from '@/utils/Person'
import { updatePriceOptions } from '@/utils/Product'

import basketStore from '@/store/basket'
import usePerson from '@/hooks/usePerson'
import usePersonDetails from '@/hooks/usePersonDetails'
import usePersonSpecificProduct from '@/hooks/usePersonSpecificProduct'
import useProduct from '@/hooks/useProduct'

export default function useProductPersons() {
  /**
   *  @file useProductPersons contains logic for products involving all persons
   */

  // COMPUTED
  const hasSelectedProducts = computed(() => {
    const { persons } = usePerson()
    if (persons.value.length === 0) return false

    return (
      persons.value.filter(person => {
        return (
          Object.keys(person.products.products).filter(productId => person.products.products[productId].selected)
            .length > 0
        )
      }).length > 0
    )
  })

  const selectedCategories = computed(() => {
    const { persons } = usePerson()
    const { hasPersonOnlyKVG, hasPersonOnlyVVG } = usePersonSpecificProduct()

    if (persons.value.length > 0) {
      return persons.value.reduce((result, p) => {
        result[p.personId] = {
          isKVGOnly: hasPersonOnlyKVG(p.personId),
          isVVGOnly: hasPersonOnlyVVG(p.personId),
        }
        return result
      }, {})
    }
    return []
  })

  /**
   *  selectedCategoriesByPerson
   *  @todo same as {@link selectedCategories}, but using personsWithDetails instead of persons
   *
   */
  const selectedCategoriesByPerson = computed(() => {
    const { personsWithDetails } = usePersonDetails()
    const { hasPersonOnlyKVG, hasPersonOnlyVVG } = usePersonSpecificProduct()
    return personsWithDetails.value.reduce((result, person) => {
      result[person.personId] = {
        isKVGOnly: hasPersonOnlyKVG(person.personId),
        isVVGOnly: hasPersonOnlyVVG(person.personId),
      }
      return result
    }, {})
  })

  // METHODS

  // @todo why am I allowed to pass no basket here? also fix name shadowing.
  function addProductDoctorToBasket(personId, productId, doctor, __basket = null) {
    const { mutateProductsInBasketForPerson } = usePersonSpecificProduct()
    let pBasket, updatedBasket
    const originalBasket = __basket || cloneDeep(basketStore.basket)

    pBasket = mutateProductsInBasketForPerson(personId, productId, { basket: originalBasket })
    pBasket = addDoctorToProduct(pBasket, productId, doctor)
    updatedBasket = updatePersonInBasket(originalBasket, personId, pBasket)
    updatedBasket = removeUnusedDoctors(updatedBasket)
    updatedBasket = addDoctorToBasket(updatedBasket, doctor)
    return Promise.resolve(updatedBasket)
  }

  function addProductToOvpBasket(personId, productId, options = {}) {
    const { mutateProductsInBasketForPerson } = usePersonSpecificProduct()
    const originalBasket = options.basket ?? cloneDeep(basketStore.basket)
    const product = originalBasket.persons.find(p => p.personId === personId).products.products[productId]
    const doctor = options.doctor || product?.doctor

    if (doctor) {
      return addProductDoctorToBasket(personId, productId, doctor, originalBasket)
    } else {
      const pBasket = mutateProductsInBasketForPerson(personId, productId, {
        basket: originalBasket,
        mode: options.mode,
      })
      let updatedBasket = updatePersonInBasket(cloneDeep(basketStore.basket), personId, pBasket)
      updatedBasket = removeUnusedDoctors(updatedBasket)
      return updatedBasket
    }
  }

  function removePersonsWithoutProductFromOvpBasket(basket) {
    const { getSelectedProductsOfPerson } = useProduct()
    const immutablePersons = basket.existingCustomer ? basket.persons.filter(p => p.partnerNumber) : []
    // @todo probably should check differently if person has products?
    const personsWithProducts = basket.persons
      .filter(p => !p.partnerNumber)
      .filter(p => getSelectedProductsOfPerson(p.products.products).length > 0)
    const payloadPersons = [...immutablePersons, ...personsWithProducts]

    return basketStore.updateOvpBasket({ persons: payloadPersons })
  }

  function selectProductOption({ personId, productId, option, __basket = null }) {
    const tmpBasket = __basket || cloneDeep(basketStore.basket)
    const pBasket = tmpBasket.persons.find(p => p.personId === personId)
    const product = pBasket.products.products[productId]

    product.prices = updatePriceOptions(product.prices, option)
    const updatedBasket = updatePersonInBasket(tmpBasket, personId, pBasket)
    return Promise.resolve(updatedBasket)
  }
  /**
   * transferProductToPerson
   * @param {string} sourceId
   * @param {string} targetId
   * @param {string} productId
   * @param {object} options
   * @returns {Promise<Basket>}
   *
   */
  async function transferProductToPerson(sourceId, targetId, productId, options = {}) {
    const { getPerson, persons } = usePerson()
    const __basket = options.basket ?? null
    const mode = options.mode ?? 'REPLACE'
    const doctor = options.doctor ?? null
    const __person = __basket ? persons.value.find(p => p.personId === sourceId) : getPerson(sourceId)
    const product = __person.products.products[productId]
    const option = product.prices.find(p => p.selected)

    const updatedBasket = await addProductToOvpBasket(targetId, productId, { basket: __basket, doctor, mode })
    return await selectProductOption({ personId: targetId, productId, option, __basket: updatedBasket })
  }

  return {
    // COMPUTED
    hasSelectedProducts,
    selectedCategories,
    selectedCategoriesByPerson,

    // METHODS
    addProductDoctorToBasket,
    addProductToOvpBasket,
    removePersonsWithoutProductFromOvpBasket,
    transferProductToPerson,
  }
}
