<template>
  <!-- prettier-ignore -->
  <div class="fabButton">
    <Form.Button
      class="button"
      :class="{
        'is-tiny': hasScrolled,
        'has-gap': scrolledToBottom,
        'hide': isVirtualKeyboardShown
      }"
      secondary
      @click="$emit('click')"
    >
      <span class="button__text">
        <the-typography
          tag="span"
          type="heading05"
          v-text="$t('fab.button')"
        />
      </span>
      <the-icon
        class="button__icon"
        art="light"
        :name="icon"
      />
    </Form.Button>
  </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'

import debounce from 'lodash/debounce'

import useBrowser from '@/hooks/useBrowser'

import * as Form from '@/components/Form'

// HOOKS
const { browser } = useBrowser()

// INIT
defineEmits(['click'])
const props = defineProps({
  hasBottomGap: {
    type: Boolean,
    default: false,
  },
  isToggleActive: {
    type: Boolean,
    default: false,
  },
})

// DATA
const hasScrolled = ref(false)
const isVirtualKeyboardShown = ref(false)
const scrollTop = ref(0)
const scrolledToBottom = ref(false)

// COMPUTED
const icon = computed(() => {
  return props.isToggleActive && isDesktop.value ? 'xmark' : 'user-headset'
})

const isDesktop = computed(() => {
  return browser.isDesktop
})

const isTouchEnabled = computed(() => {
  // @TODO check removal of legacy code
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
})

const windowHeight = computed(() => {
  return window.innerHeight || (document.documentElement || document.body).clientHeight
})

// METHODS
const debouncedScrolledToBottomHandler = debounce(handleScrolledToBottom, 50)

function handleFocusIn(event) {
  if (hasKeyboard(event.target)) {
    isVirtualKeyboardShown.value = true
  }
}

function handleFocusOut() {
  isVirtualKeyboardShown.value = false
}

function handleScroll() {
  hasScrolled.value = true
}

function handleScrolledToBottom() {
  if (isDesktop.value) {
    scrolledToBottom.value = false
  } else {
    scrollTop.value =
      window.pageYOffset || (document.documentElement || document.body?.parentNode || document.body).scrollTop
    scrolledToBottom.value = scrollTop.value > windowHeight.value
  }
}

function hasKeyboard(target) {
  return (
    isTouchEnabled.value &&
    target.tagName === 'INPUT' &&
    ['TEXT', 'NUMBER', 'PASSWORD'].includes(target.type.toUpperCase())
  )
}

// WATCHERS
watch(isDesktop, value => {
  if (props.hasBottomGap) {
    handleScrolledToBottom()
  }
  if (value) {
    window.removeEventListener('focusin', handleFocusIn)
    window.removeEventListener('focusout', handleFocusOut)
    isVirtualKeyboardShown.value = false
  } else {
    window.addEventListener('focusin', handleFocusIn)
    window.addEventListener('focusout', handleFocusOut)
  }
})

// LIFECYCLE HOOKS
onMounted(() => {
  window.addEventListener('scroll', handleScroll, { once: true })
  if (props.hasBottomGap) {
    window.addEventListener('scroll', debouncedScrolledToBottomHandler)
  }
  if (!isDesktop.value) {
    window.addEventListener('focusin', handleFocusIn)
    window.addEventListener('focusout', handleFocusOut)
  }
})
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll, { once: true })
  if (props.hasBottomGap) {
    window.removeEventListener('scroll', debouncedScrolledToBottomHandler)
  }
  if (!isDesktop.value) {
    window.removeEventListener('focusin', handleFocusIn)
    window.removeEventListener('focusout', handleFocusOut)
  }
})
</script>

<style name="mobile" scoped>
.fabButton {
  .button {
    position: fixed;
    bottom: 24px;
    right: 24px;
    width: auto;
    background-color: var(--c-primary-neutral-3);
    border: none;
    box-shadow:
      0 6px 6px 0 rgba(0, 0, 0, 0.24),
      0 0 6px 0 rgba(0, 0, 0, 0.12);
    z-index: 8998;

    &.is-tiny {
      padding: 5px;
      min-width: 50px;

      .button__text {
        display: none;
      }

      .button__icon {
        margin-left: 0;
      }
    }

    &.has-gap {
      bottom: 64px;
    }

    &.hide {
      display: none;
    }

    &:hover {
      .button__icon {
        color: var(--c-primary-color-2);
      }
    }

    &:focus {
      .button__icon {
        color: var(--c-primary-neutral-3);
      }
    }
  }

  .button__text {
    display: inline-block;
    vertical-align: middle;
  }

  .button__icon {
    display: inline-block;
    margin-left: 8px;
    font-size: 24px;
    vertical-align: middle;
  }
}
</style>
