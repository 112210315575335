<template>
  <form
    class="search-form"
    @submit.prevent="handleSubmit"
  >
    <div class="search-form__content">
      <div class="search-form__title">
        <the-typography
          tag="span"
          type="heading04"
          v-text="getTranslation('form.title')"
        />
      </div>
      <Form.Location
        ref="zipChooserRef"
        v-model="formData.location"
        contract-start-date="2021-01-01"
        name="$props.location"
        required
        theme="dark"
        :title-label="getTranslation('form.zipCode.title')"
        @focus="$emit('focus')"
        @update:modelValue="handleSubmit"
      />
    </div>
  </form>
</template>

<script setup>
import { computed, reactive, watch } from 'vue'

import useMedicalOffice from '@/hooks/useMedicalOffice'

import * as Form from '@/components/Form'

// HOOKS
const { getTranslation } = useMedicalOffice()

// INIT
const emit = defineEmits(['blur', 'focus', 'search'])
const props = defineProps({
  location: {
    type: Object,
    default: () => {},
    required: true,
  },
})

// DATA
const formData = reactive({
  location: { ...props.location },
})

// COMPUTED
const isValid = computed(() => {
  return Boolean(formData.location?.zipCode)
})

// METHODS
function handleSubmit() {
  if (isValid.value) {
    emit('search', formData.location)
  }
}

// WATCHERS
watch(
  () => props.location,
  location => {
    if (!location) {
      return
    }
    formData.location = location
  }
)
</script>

<style scoped>
.search-form {
  height: auto;

  &__content {
    margin: 25px 15px 10px 15px;
  }

  &__title {
    padding: 0 0 24px;
  }

  &__actions {
    display: flex;
    margin: 0 25px;

    button {
      z-index: 1;
    }
  }
}

@media (--md) {
  .search-form {
    &__content {
      margin: 30px 30px 10px 30px;
    }

    &__actions {
      width: 200px;
      margin: 0 30px;
      button {
        width: 100%;
      }
    }
  }
}
</style>
