<template>
  <!-- prettier-ignore -->
  <div class="modal">
    <div
      class="modal-forms"
      :class="{'modal-form--two-form' : isExtendedFormShown}"
    >
      <save-form
        class="modal-form"
        tiny
        :cancelable="$props.isSaveWithoutApplicationData"
        :data="data.save"
        :is-save-without-application-data="$props.isSaveWithoutApplicationData"
        @cancel="cancel"
        @save="data => save(data, true)"
      />

      <save-form
        v-if="isExtendedFormShown"
        class="modal-form modal-form--secondary"
        :data="data.send"
        @save="save"
      />
    </div>

    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
    <div slot="footer">
      <div class="modal-disclaimer">
        <protection
          :title-label="$t(`basket.modal.basketScreenDataProtection`)"
          :toggleable="$props.isSaveWithoutApplicationData"
        >
          <span v-html="$t(`basket.modal.${disclaimerText}`)" />
        </protection>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import cloneDeep from 'lodash/cloneDeep'

import { events$, loading$ } from '@/services'
import { DateTimeHelper } from '@/helpers'
import { logError } from '@/helpers/Logger'
import { updatePersonInBasket } from '@/utils/Person'
import { ReactivityUtil } from '@/utils/Reactivity'

import basketStore from '@/store/basket'
import useBrowser from '@/hooks/useBrowser'
import useI18n from '@/hooks/useI18n'
import usePerson from '@/hooks/usePerson'
import useToast from '@/hooks/useToast'

import Protection from '@/components/BasketWidget/Modals/Protection'
import SaveForm from '@/components/BasketWidget/Modals/SaveForm'

import { EVENT_BASKET } from '@/config/events'

// HOOKS
const { browser } = useBrowser()
const { t } = useI18n()
const { getPerson } = usePerson()
const { addToast } = useToast()

// INIT
const emit = defineEmits(['canceled', 'saved'])
const props = defineProps({
  isSaveWithoutApplicationData: {
    type: Boolean,
    default: false,
  },
  source: {
    type: String,
    required: true,
    validator: val => !!val,
  },
})

// COMPUTED
const data = computed(() => {
  const { firstName, lastName } = namesOfContractOwner.value

  return {
    save: {
      email: basketStore.basket.email,
    },
    send: {
      email: basketStore.basket.email,
      mobile: basketStore.basket.mobile,
      firstName,
      lastName,
    },
  }
})

const disclaimerText = computed(() => {
  const suffix = props.isSaveWithoutApplicationData ? '' : '_B'
  return `basketScreenDataProtectionText${suffix}`
})

const headOfContract = computed(() => {
  if (basketStore.basket.contractOwner?.personid) {
    return basketStore.basket.contractOwner.personId
  } else {
    let adult
    let person = (adult = basketStore.basket.persons.find(p => DateTimeHelper.getAge(p.personData.dateOfBirth) >= 18))
    if (!adult) person = basketStore.basket.persons[0]

    return person.personId
  }
})

const isExtendedFormShown = computed(() => {
  return !props.isSaveWithoutApplicationData && !basketStore.basket.existingCustomer
})

const namesOfContractOwner = computed(() => {
  const { firstName, lastName } = getPerson(headOfContract.value).personData
  return {
    firstName: firstName ?? null,
    lastName: lastName ?? null,
  }
})

// METHODS
function cancel() {
  emit('canceled')
}

function getUpdatedBasket(payload) {
  // update Person
  if (payload.firstName && payload.lastName) {
    const { firstName, lastName } = payload

    const personData = Object.assign(getPerson(headOfContract.value).personData, { firstName, lastName })

    const personPayload = updatePersonInBasket(cloneDeep(basketStore.basket), headOfContract.value, { personData })

    // set family name for all people, that haven't had it set yet
    personPayload.persons.map(person => {
      if (!person.personData.lastName) person.personData.lastName = lastName
    })

    return Object.assign({}, personPayload, { email: payload.email, mobile: payload.mobile })
  } else {
    return payload
  }
}

async function save(payload, isTiny = false) {
  loading$.start({ blocking: false })

  const params = {
    emailType: !payload.mobile ? 'OFFER_REENTRY_WITHOUT_EGATE' : 'OFFER_REENTRY',
    isDesktop: browser.isDesktop,
  }

  try {
    const offerUpdate = !!ReactivityUtil.clone(basketStore.basket.oaBasketId)
    let _basket = await getUpdatedBasket(payload)
    _basket = await basketStore.updateOvpBasket(_basket, {}, params)

    addToast({
      text: t(`basket.modal.basketSaved`),
    })

    events$.emit(EVENT_BASKET.SAVED, {
      basket: _basket,
      source: isTiny ? `${props.source}-tiny` : props.source,
      update: offerUpdate,
    })
    loading$.end()
    return emit('saved')
  } catch (error) {
    logError(error)
    loading$.failed()
  }
}
</script>

<style name="mobile" scoped>
.modal {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.modal-forms {
  display: flex;
  flex-direction: column;

  &.modal-form--two-form .modal-form {
    padding: 16px;
  }
}

.modal-form--secondary {
  background-color: var(--c-primary-neutral-2);
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .modal-forms {
    flex-direction: row;

    .modal-form {
      flex: 1 1 100%;
    }
  }
}
</style>
