<template>
  <!-- prettier-ignore -->
  <transition-group
		class="toasts"
		name="toasts"
		tag="div"
  >
		<div
			v-for="toast in toasts"
			:key="toast.id"
			class="toast"
			:class="getToastClasses(toast)"
    >
			<div class="toast__firstRow">
				<the-typography
					class="toast__text"
					type="heading05"
					v-html="toast.text"
				/>
				<the-button
					v-if="toast.isClosable"
					class="toast__close"
					icon="xmark"
					icon-art="solid"
					link
					@click="removeToast(toast.id)"
				/>
			</div>

			<div class="toast__actions">
				<template
					v-for="action in toast.actions"
					:key="action.id"
        >
					<the-button
						class="toast__action"
						link
						:text="action.label"
						@click="action.callback(toast.id)"
					/>
				</template>
			</div>
		</div>
	</transition-group>
</template>

<script setup>
import useToast from '@/hooks/useToast'

import { Button as TheButton } from '@/components/Form'

import { NOTIFICATION } from '@/config/constants'

// HOOKS
const { removeToast, toasts } = useToast()

// METHODS
function getToastClasses(toast) {
  return {
    'toast--theme-error': toast.type === NOTIFICATION.ERROR,
    'toast--theme-info': toast.type === NOTIFICATION.INFO,
    'toast--theme-warning': toast.type === NOTIFICATION.WARNING,
  }
}
</script>

<style name="animations" scoped>
.toasts-enter-active {
  animation: enter 0.5s;
}

.toasts-leave-active {
  animation: leave 0.5s;
}

@keyframes enter {
  0% {
    opacity: 0;
    transform: scale(1.2, 1.2) translateY(0);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1) translateY(0);
  }
}

@keyframes leave {
  0% {
    opacity: 1;
    transform: scale(1, 1) translateY(0);
  }
  100% {
    opacity: 0;
    transform: scale(0.8, 0.8) translateY(0);
  }
}
</style>

<style name="mobile" scoped>
.toasts {
  position: fixed;
  left: 5%;
  right: 5%;
  bottom: 20px;

  cursor: default;
  display: flex;
  flex-direction: column-reverse;
  z-index: 100000;
  gap: 8px;

  .toast {
    background-color: var(--c-primary-neutral-1);

    padding: 12px 16px;
    min-height: 50px;

    .toast {
      &__text {
        color: var(--c-primary-neutral-3);
      }

      &__close {
        min-height: initial;
        min-width: initial;
        width: auto;
        height: auto;

        padding: 0;
        margin: 0;
        border: 0;
        border-radius: 0;
        font-size: 20px;
        align-items: initial;
        justify-content: initial;
      }

      &__action {
        height: 24px;
        min-height: 24px;
        padding: 0;
        min-width: 0;
      }
    }

    .toast__firstRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 4px;
    }

    .toast__actions {
      display: flex;
      flex-direction: row;
      align-content: flex-end;
      justify-content: flex-end;
      gap: 8px;
    }
  }

  .toast--theme-info {
    background-color: var(--c-primary-color-1);
    color: var(--c-primary-neutral-3);
    box-shadow:
      0 6px 10px 0 rgba(0, 0, 0, 0.14),
      0 1px 18px 0 rgba(0, 0, 0, 0.12),
      0 3px 5px -1px rgba(0, 0, 0, 0.4);

    .toast__text,
    :deep(.toast__close .icon) {
      color: var(--c-primary-neutral-3);
    }

    .toast__actions {
      color: var(--c-primary-color-3);
    }
  }

  .toast--theme-warning {
    background-color: #fff7c6;
    box-shadow:
      0 6px 10px 0 rgba(0, 0, 0, 0.14),
      0 1px 18px 0 rgba(0, 0, 0, 0.12),
      0 3px 5px -1px rgba(0, 0, 0, 0.4);

    .toast__text,
    :deep(.toast__close .icon) {
      color: #15141a;
    }

    .toast__actions {
      color: var(--c-primary-color-3);
    }
  }

  .toast--theme-error {
    background-color: #faccce;
    box-shadow:
      0 6px 10px 0 rgba(0, 0, 0, 0.14),
      0 1px 18px 0 rgba(0, 0, 0, 0.12),
      0 3px 5px -1px rgba(0, 0, 0, 0.4);

    .toast__text,
    :deep(.toast__close .icon) {
      color: #15141a;
    }

    .toast__actions {
      color: var(--c-primary-color-3);
    }
  }
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .toasts {
    width: 544px;
    left: calc(50% - (544px / 2));
  }
}
</style>
