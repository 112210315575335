<template>
  <!-- prettier-ignore -->
  <div class="results-list">
    <results-card
      v-for="(office, key) in $props.results"
      :key="key"
      :active="isActive(office)"
      :auto-scroll="$props.autoScroll"
      :hover="isHover(office)"
      :office="office"
      :product="$props.product"
    />

    <template v-if="$props.results.length > 0">
      <the-message
        v-if="hasLabel('messages.noResults')"
        class="results-list__message"
        severity="info"
        :text="getTranslation('messages.noResults')"
      />
    </template>
    <template v-else>
      <the-message
        v-if="hasLabel('messages.emptyResults.text')"
        class="results-list__message"
        severity="warning"
      >
        <div class="results-list__message-empty">
          <div v-html="getTranslation('messages.emptyResults.text')" />
          <a
            class="results-list__message-empty__link"
            @click="closeSearch"
            v-text="getTranslation('messages.emptyResults.link')"
          />
        </div>
      </the-message>
    </template>
  </div>
</template>

<script setup>
import useMedicalOffice from '@/hooks/useMedicalOffice'

import ResultsCard from '@/components/MedicalSearch/components/ResultsCard'

// HOOKS
const { getTranslation, hasLabel } = useMedicalOffice()

// INIT
const emit = defineEmits(['close'])
const props = defineProps({
  autoScroll: {
    type: Boolean,
    default: false,
  },
  hovered: {
    type: String,
    default: '',
  },
  product: {
    type: Object,
    default: () => undefined,
  },
  results: {
    type: Array,
    default: () => [],
  },
  selected: {
    type: String,
    default: '',
  },
})

// METHODS
function closeSearch() {
  emit('close')
}

function isActive(office) {
  return office.zsrNumber === props.selected
}

function isHover(office) {
  return office.zsrNumber === props.hovered
}
</script>

<style scoped>
.results-list {
  position: relative;
  margin: 10px 0 0 0;
  overflow-y: auto;
  border-top: 1px solid var(--c-primary-neutral-1);

  &__head {
    position: absolute;
    top: -90px;
    right: 0;
    width: 100%;
    height: 90px;
    max-width: 160px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;

    &--sticky {
      z-index: 1;
      position: fixed;
      top: 0;
      left: 0;
      max-width: 433px;
      background-color: var(--c-primary-neutral-3);
    }
  }

  &__title {
    position: relative;
    font-size: 12px;
    line-height: 20px;
    width: 50px;

    div {
      position: absolute;
      bottom: -20px;
      right: -22px;
      transform: rotate(-80deg);
      display: inline-block;
      height: 100%;
      width: 100%;
    }
  }

  &__message {
    border-top: 1px solid var(--c-primary-neutral-1);
    padding: 15px 25px;

    &-empty {
      display: flex;
      flex-direction: column;
      font-weight: bold;

      &__link {
        padding-top: 5px;
        align-self: flex-end;
      }
    }
  }
}
</style>
