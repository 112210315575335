<template>
  <div class="toggler">
    <div :class="['toggle', { active: $props.modelValue }]">
      <div
        class="toggle-item"
        :class="{ 'toggle-item--active': $props.modelValue === true }"
        @click="select(true)"
      >
        {{ getTranslation('toggle.list') }}
        <input
          name="map-toggle"
          type="radio"
          :checked="$props.modelValue === true"
          :value="true"
        />
        <span class="left" />
      </div>
      <div
        class="toggle-item"
        :class="{ 'toggle-item--active': $props.modelValue === false }"
        @click="select(false)"
      >
        {{ getTranslation('toggle.map') }}
        <input
          name="map-toggle"
          type="radio"
          :checked="$props.modelValue === false"
          :value="false"
        />
        <span class="right" />
      </div>
    </div>
  </div>
</template>

<script setup>
import useMedicalOffice from '@/hooks/useMedicalOffice'

// HOOKS
const { getTranslation } = useMedicalOffice()

// INIT
const emit = defineEmits(['update:modelValue'])
defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})

// METHODS
function select(value) {
  emit('update:modelValue', value)
}
</script>

<style scoped>
.toggler {
  display: inline-block;
  user-select: none;
  margin: 0 auto;
}

.toggle {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  max-width: 100%;
  box-shadow: 0 0 4px 0 rgba(19, 19, 19, 0.75);
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.toggle--active .toggle-item:after {
  display: none;
}

.toggle--active .toggle-item--active {
  border-color: var(--c-primary-color-3);
  color: var(--c-primary-color-3);
}

.toggle-item {
  position: relative;
  text-align: center;
  border: 1px solid var(--c-primary-color-3);
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  color: var(--c-primary-color-3);
  background-color: var(--c-primary-neutral-3);

  display: inline-flex;
  flex-direction: row;
  align-items: center;

  font-family: Open Sans;
  font-size: 12px;
  min-height: 30px;
  max-height: 30px;
  padding: 5px 20px;
  width: auto;

  input {
    position: absolute;
    display: inline-block;
    opacity: 0;
    height: 1px;
    width: 1px;

    &:focus + span {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.75);

      &.left {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
      }

      &.right {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
      }
    }
  }
}

.toggle-item > *,
.toggle-item :deep(*) {
  pointer-events: none;
}

.toggle-item--active {
  border-color: var(--c-primary-color-3);
  background-color: var(--c-primary-color-3);
  color: var(--c-primary-neutral-3) !important;
}

.toggle-item:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-right: none;
}

.toggle-item:last-child {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-left: none;
}

@media (--md) {
  .toggle:hover {
    background-color: var(--c-primary-color-3);
    color: var(--c-primary-neutral-3);
  }
}
</style>
