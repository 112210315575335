import { deburr } from 'lodash'

import countries from '@/assets/countries.json'
import { SortUtil } from '@/utils/Sorting'

// refactor => helper/util pass countries as param
export const getCountries = (language, countryCodeFilter) => {
  language = language.toLowerCase()
  const data = countries
    .filter(country => {
      return countryCodeFilter ? countryCodeFilter.includes(country.code) : true
    })
    .map(country => {
      return Object.assign(country, {
        text: country.translations[language],
      })
    })

  const sortedAdditionalItems = data.slice(3).sort(SortUtil.sortByParam(item => deburr(item.text)))
  return data.slice(0, 3).concat(sortedAdditionalItems)
}
