import { computed } from 'vue'

import { ContactUtil } from '@/utils/Contact'

import partnerStore from '@/store/partner'
import useI18n from '@/hooks/useI18n'

export default function useContact() {
  // HOOKS
  const { t } = useI18n()

  // COMPUTED
  const contactInfo = computed(() => {
    if (!partnerStore.partner?.serviceCenterInfo) return

    const data = partnerStore.partner.serviceCenterInfo

    return {
      alva: data.alva,
      chat: data.chatOpeningTime ? processChat(data) : false,
      cobrowsing: data.coBrowsingOpeningTime ? processCoBrowsing(data) : false,
      email: data.email,
      phone: data.openingTime ? processPhone(data) : false,
    }
  })

  // METHODS
  function processChat({ chatOpeningTime }) {
    return {
      active: ContactUtil.amIOpen(chatOpeningTime),
      enabled: chatOpeningTime.length > 0,
      times: chatOpeningTime.map(line => processOpening(line)),
    }
  }

  function processCoBrowsing({ coBrowsingOpeningTime }) {
    return {
      active: ContactUtil.amIOpen(coBrowsingOpeningTime),
      enabled: coBrowsingOpeningTime.length > 0,
      times: coBrowsingOpeningTime.map(line => processOpening(line)),
    }
  }

  function processOpening(line) {
    const startDay = t(`date.days.${line.start}`)
    const endDay = t(`date.days.${line.end}`)

    return {
      days: `${startDay} - ${endDay}`,
      hours: `${line.startTime} - ${line.endTime}`,
    }
  }

  function processPhone({ name, phoneNumber, openingTime }) {
    return {
      name,
      active: ContactUtil.amIOpen(openingTime),
      enabled: true,
      nr: phoneNumber,
      times: openingTime.map(line => processOpening(line)),
    }
  }

  return {
    contactInfo,
  }
}
