<template>
  <!-- prettier-ignore -->
  <div
    class="bwProduct"
    :class="classes"
  >
    <div
      class="bwProduct__name"
      v-text="$t(`${content}.name`)"
    />

    <div class="bwProduct-price">
      <the-icon
        v-for="(icon, index) in icons"
        :key="index"
        v-tooltip="icon.tooltip"
        class="bwProduct__icon"
        art="light"
        html-tooltip
        :name="icon.name"
      />

      <product-price
        class="bwProduct__price"
        :price="$props.product.price.price"
      />

      <a
        v-if="!$props.readonly"
        class="bwProduct__delete"
        @click="removeProduct"
      >
        <the-icon
          art="light"
          name="trash"
        />
      </a>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { events$, loading$ } from '@/services'
import { confirm } from '@/utils/Modal'

import basketStore from '@/store/basket'
import useI18n from '@/hooks/useI18n'
import usePersonSpecificProduct from '@/hooks/usePersonSpecificProduct'
import useStatus from '@/hooks/useStatus'

import ProductPrice from '@/components/Product/ProductPrice'

import { EVENT_PRODUCT, SOURCE } from '@/config/events'

const { t } = useI18n()
const { removeProductFromPerson } = usePersonSpecificProduct()
const { isOnboardingSecured } = useStatus()

// INIT
const props = defineProps({
  personId: {
    type: String,
    required: true,
  },
  product: {
    type: Object,
    required: true,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  source: {
    type: String,
    default: SOURCE.BASKET_WIDGET,
  },
})

// COMPUTED
const classes = computed(() => {
  return {
    'bwProduct--invalid': messages.value.find(m => m.type !== 'info'),
  }
})

const content = computed(() => {
  return `content.products.${props.product.productId}`
})

const icons = computed(() => {
  const _icons = []

  if (props.product.price.reductions.length > 0) {
    let label = []
    props.product.price.reductions.forEach(r => {
      const _info = t(`info.${r.type}`)

      return label.push(`${_info} ${Math.abs(r.amount)} ${t('product.currency')} `)
    })

    _icons.push({
      name: 'badge-percent',
      tooltip: label.join('; '),
    })
  }

  messages.value
    .filter(m => m.type !== 'info')
    .forEach(m => {
      _icons.push({
        name: 'exclamation-circle',
        tooltip: t(`error.${m.key}`),
      })
    })

  return _icons
})

const messages = computed(() => {
  return (
    props.product.messages.map(message => {
      return {
        key: message.key,
        type: message.type.toLowerCase(),
      }
    }) || []
  )
})

// METHODS
async function askRemovalConfirmation(_payload) {
  const label = 'product.confirmRemoval'

  const secured = isOnboardingSecured.value
  return !secured || (await confirm({ label: t(label) }))
}

async function removeProduct() {
  const personId = props.personId
  const productId = props.product.productId
  const source = props.source

  if (!(await askRemovalConfirmation(basketStore.basket))) {
    return
  }

  loading$.start()

  try {
    await removeProductFromPerson(personId, productId)
    loading$.end()

    events$.emit(EVENT_PRODUCT.REMOVED, {
      baset: basketStore.basket,
      categoryId: props.categoryId,
      personId,
      productId,
      source,
    })
  } catch (error) {
    console.log('error', error)
    loading$.failed()
  }
}
</script>

<style name="mobile" scoped>
.bwProduct {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  line-height: 25px;

  &-price {
    position: relative;
    white-space: nowrap;
  }

  &__icon {
    cursor: help;
    font-size: 18px;
    margin-right: 5px;
    color: var(--c-primary-neutral-1);
  }

  &__price {
    margin-right: 25px;
  }

  &__delete {
    position: absolute;
    right: -13px;
    top: 1px;
    width: 40px;
    line-height: 20px;
    text-align: center;

    &:hover {
      border-bottom: initial;
    }
  }

  &--invalid {
    color: var(--c-secondary-color-1);

    .icon {
      color: var(--c-secondary-color-1);
    }
  }
}
</style>
