import Logo from '@/components/MedicalSearch/assets/sanitas-logo.svg'

import Cluster from '@/components/MedicalSearch/assets/cluster.svg'
import ClusterHovered from '@/components/MedicalSearch/assets/cluster_hovered.svg'

import Marker from '@/components/MedicalSearch/assets/marker.svg'
import MarkerHovered from '@/components/MedicalSearch/assets/marker_hovered.svg'
import MarkerActive from '@/components/MedicalSearch/assets/marker_active.svg'

export const SanitasLogo = Logo

export const MapIconCluster = Cluster
export const MapIconClusterHover = ClusterHovered

export const MapIcon = Marker
export const MapIconHover = MarkerHovered
export const MapIconActive = MarkerActive
