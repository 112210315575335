import { computed, reactive } from 'vue'
import isNil from 'lodash/isNil'

import { ReactivityUtil } from '@/utils/Reactivity'
import { DateTimeUtil } from '@/utils/DateTime'
import { SortUtil } from '@/utils/Sorting'

// INIT
const ITEMS = [
  { value: 'YES', text: 'form.yes' },
  { value: 'NO', text: 'form.no' },
]
const ITEMS_BOOLEAN = [
  { value: true, text: 'form.yes' },
  { value: false, text: 'form.no' },
]

// HealthQuestion info ids that are replaced by tooltips
const TOOLTIP_HQ_INFOS = [
  'INFO_TREATMENT_2',
  'INFO_TREATMENT_3',
  'INFO_DRUGS_1',
  'INFO_PF_FERTILITY',
  'INFO_PF_HEREDITARY_DISEASES',
  'INFO_PF_ENDOMETRIOSIS',
  'INFO_PF_MENSTRUATION',
]

// Validations/Status-Checks START
const answeredYes = __hq => __hq.mainAnswer === 'YES'
const notAnswered = __hq => isNil(__hq.mainAnswer)
const isNotEmpty = arr => arr?.length > 0
const isPositiveNr = num => num > 0
const isAnsweredAndValid = (__hq, fieldNames) => {
  if (notAnswered(__hq)) return false
  if (!answeredYes(__hq)) return true
  return fieldNames.some(fieldName => isNotEmpty(__hq[fieldName]))
}
const hasValidSubAnswer = (__hq, fieldName) => {
  if (notAnswered(__hq)) return false
  if (!answeredYes(__hq)) return true
  if (isNil(__hq.subAnswers)) return false

  return !!__hq.subAnswers[fieldName]
}
const allDBCasesAnswered = __hq => {
  const subQuestions = [
    'dentalDenture',
    'dentalMissingTeeth',
    'dentalDeviateTeeth',
    'dentalGumDisease',
    'dentalAccident',
    'dentalAnomaly',
    'dentalRetention',
  ]
  return subQuestions.every(subQuestion => __hq.subAnswers && typeof __hq.subAnswers[subQuestion] === 'boolean')
}
const hasAnsweredWithValidDate = __hq => {
  if (notAnswered(__hq)) return false
  if (!answeredYes(__hq)) return true

  const { year, month } = __hq.subAnswers?.treatmentDate || {}
  if (!year || !month) return false

  const isInFuture = DateTimeUtil.isInFuture(`${year}-${month}-01`)
  return isNotEmpty(__hq.doctorIds) && !isInFuture
}
const dbCasesRequired = allAnswers => {
  const preconditions = _hq =>
    ['DB_TREATMENTS', 'DB_DEFECTS'].some(condition => {
      return condition === _hq.healthQuestionId && answeredYes(_hq)
    })
  return allAnswers.some(_hq => preconditions(_hq))
}

function healthQuestionConfig(hq, allAnswers) {
  switch (hq.healthQuestionId) {
    case 'BABY_WEIGHT':
      return isPositiveNr(hq.subAnswers?.birthWeight)
    case 'HEIGHT_WEIGHT':
      return isPositiveNr(hq.subAnswers?.height) && isPositiveNr(hq.subAnswers?.weight)
    case 'LIFESTYLE_MALE':
      return isAnsweredAndValid(hq, ['smokingConsumptions', 'alcoholConsumptions', 'drugConsumptions'])
    case 'LIFESTYLE_FEMALE':
      return isAnsweredAndValid(hq, ['smokingConsumptions', 'alcoholConsumptions', 'drugConsumptions'])
    case 'TREATMENTS':
      return isAnsweredAndValid(hq, ['treatmentCases'])
    case 'DRUGS':
      return isAnsweredAndValid(hq, ['medicines'])
    case 'DB_DEFECTS':
      return !notAnswered(hq)
    case 'IV':
      return !notAnswered(hq)
    case 'PF_CANCER':
      return !notAnswered(hq)
    case 'PF_FERTILITY':
      return !notAnswered(hq)
    case 'PF_ENDOMETRIOSIS':
      return !notAnswered(hq)
    case 'PF_MENSTRUATION':
      return !notAnswered(hq)
    case 'DB_TREATMENTS':
      return !notAnswered(hq)
    case 'PF_TREATMENTS':
      return !notAnswered(hq)
    case 'PF_PLANNEDTREATMENTS':
      return !notAnswered(hq)
    case 'JAW':
      return !notAnswered(hq)
    case 'PF_HEREDITARY_DISEASES':
      return hasValidSubAnswer(hq, 'hereditaryDisease')
    case 'DB_CASES':
      return !dbCasesRequired(allAnswers) || allDBCasesAnswered(hq)
    case 'DB_CONTROL':
      return hasAnsweredWithValidDate(hq)
    case 'DB_CONTROL_Y':
      return hasAnsweredWithValidDate(hq)
    case 'DB_CONTROL_Y_U7':
      return hasAnsweredWithValidDate(hq)
    case 'DB_HYGIENE':
      return hasAnsweredWithValidDate(hq)
    case 'DB_FILLINGS':
      return hasValidSubAnswer(hq, 'fillings')
    default:
      !notAnswered(hq)
  }
}
// Validations/Status-Checks END

// DATA
const activeCase = reactive({})
const doctors = reactive([])
let tempDoctors = []

// COMPUTED
const activeCaseId = computed(() => {
  return activeCase.hqId ?? false
})

const isModalActive = computed(() => {
  return !!activeCase.data
})

// METHODS
/**
 * Temporarily store doctors from form-component until the parent-form is saving/submitting the formdata via updateDoctors
 **/
function cacheDoctors(__doctors) {
  tempDoctors = __doctors
}

function clearActiveCase() {
  ReactivityUtil.resetData(activeCase)
}

// Based on the passed data object ensure that all keys from the MODEL are present in the resulting object.
function ensureModel(MODEL, data) {
  const result = data || {}
  for (const key in MODEL) {
    if (typeof MODEL[key] === 'object' && MODEL[key] !== null) {
      if (result[key]) {
        ensureModel(MODEL[key], result[key])
      } else {
        result[key] = MODEL[key]
      }
    }
  }
  return result
}

/**
 *  initHealthQuestionsForPerson removes info-ids that are replaced by tooltips
 *  so that they no longer appear as message boxes for a healthquestion
 */
function initHealthQuestionsForPerson(person) {
  const __person = ReactivityUtil.clone(person)
  __person.healthQuestionAnswers = __person.healthQuestionAnswers
    .map(hq => {
      return {
        ...hq,
        infos: hq.infos.filter(infoId => !TOOLTIP_HQ_INFOS.includes(infoId)),
      }
    })
    .sort(SortUtil.sortByOrder)

  return __person
}

function setActiveCase(
  params
  //data, index, hqId, type
) {
  ReactivityUtil.reAssign(activeCase, params)
}

/**
 * Write all doctors previously stored in cacheDoctors for updating basket
 **/
function updateDoctors() {
  tempDoctors.forEach(doctor => {
    const idx = doctors.findIndex(d => d.doctorId === doctor.doctorId)
    if (idx >= 0) {
      doctors[idx] = doctor
    } else {
      doctors.push(doctor)
    }
  })
  // Clear cached doctors
  tempDoctors = []
}

// Export
export default function useHealthquestion() {
  return {
    healthQuestionConfig,
    doctors,

    // Getters
    activeCase,
    activeCaseId,
    isModalActive,

    // Setters
    clearActiveCase,
    setActiveCase,

    //ENUMS
    ITEMS,
    ITEMS_BOOLEAN,

    // Methods
    cacheDoctors,
    ensureModel,
    initHealthQuestionsForPerson,
    updateDoctors,
  }
}
