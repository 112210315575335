import { isRequired } from '@/utils/CustomErrors'

import useAuth from '@/hooks/useAuth'

import { LOGIN_ERROR_STATE } from '@/config/constants'

const { getToken } = useAuth()

const baseUrl = import.meta.env.VITE_SANITAS_MICROSERVICE_URI

const defaultHeaders = token => {
  return {
    Accept: ['application/json', 'text/plain', '*/*'],
    Authorization: `Bearer ${token}`,
    'X-Requested-With': 'XMLHttpRequest',
  }
}

export const AuthHelper = {
  async getFamilyBasket(platform) {
    const token = await getToken(platform)
    if (!token) {
      throw new Error(LOGIN_ERROR_STATE.GENERAL)
    }

    const response = await fetch(`${baseUrl}ovpv3/customer/get-family`, {
      headers: defaultHeaders(token),
    })

    if (response.status !== 200) {
      throw new Error(LOGIN_ERROR_STATE.GENERAL)
    }

    const resData = await response.json()
    return resData.data
  },

  async createBasket({ payload = isRequired('payload'), platform }) {
    const token = await getToken(platform)
    if (!token) {
      throw new Error(LOGIN_ERROR_STATE.GENERAL)
    }

    const body = JSON.stringify(payload)
    const response = await fetch(`${baseUrl}ovpv3/customer/baskets`, {
      method: 'POST',
      headers: { ...defaultHeaders(token), 'Content-Type': 'application/json' },
      body,
    })

    const resData = await response.json()
    return resData.data
  },
}
