<template>
  <!-- prettier-ignore -->
  <div class="bwPerson">
    <the-typography type="heading06">
      <div class="bwPerson-name">
        <person-name
          class="bwPerson-personal"
          :person="$props.person"
        />
        <div class="bwPerson-price">
          <product-price
            class="bwPerson__price"
            :price="$props.person.total"
          />
        </div>
      </div>
    </the-typography>

    <template v-if="browser.isDesktop">
      <the-typography type="bodyMediumLong">
        <div class="bwPerson-products">
          <template
            v-for="product in sortedProducts"
            :key="product.productId"
          >
            <basket-widget-product
              :person-id="$props.person.personId"
              :product="product"
              :readonly="$props.readonly"
            />
          </template>
        </div>
      </the-typography>
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import useBrowser from '@/hooks/useBrowser'
import usePersonDetails from '@/hooks/usePersonDetails'
import usePersonSpecificProduct from '@/hooks/usePersonSpecificProduct'

import BasketWidgetProduct from '@/components/BasketWidget/Container/Product'
import PersonName from '@/components/Person/PersonName'
import ProductPrice from '@/components/Product/ProductPrice'

// HOOKS
const { browser } = useBrowser()
const { getPersonWithDetails } = usePersonDetails()
const { getSelectedProductsByCategory } = usePersonSpecificProduct()

// INIT
const props = defineProps({
  person: {
    type: Object,
    required: true,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
})

// COMPUTED
const sortedProducts = computed(() => {
  const selectedProducts = getPersonWithDetails(props.person.personId).selectedProducts
  return getSelectedProductsByCategory(selectedProducts).reduce((acc, value) => acc.concat(value.products), [])
})
</script>

<style name="mobile" scoped>
.bwPerson {
  padding: 15px 0;
  user-select: none;
  cursor: default;
}

.bwPerson-name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bwPerson-personal {
  max-width: 200px;
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .bwPerson-products {
    margin-top: 10px;
  }

  .bwPerson__price {
    margin-right: 25px;
  }
}
</style>
