import { reactive, readonly } from 'vue'

import { logInfo } from '@/helpers/Logger'
import { getDistributionPartnerNumber } from '@/helpers/Partner'

import incentiveAPI from '@/api/incentive'
import useBasket from '@/hooks/useBasket'

import { ReactivityUtil } from '@/utils/Reactivity'
import { CHANNEL } from '@/config/constants'

// GLOBAL DATA
const incentive = reactive({})

export default function useIncentive() {
  // HOOKS
  const { hasImmigratedPerson } = useBasket()

  // METHODS
  async function installIncentiveOnUpdate(basket, originalBasket) {
    if (!basket.basketId) return

    const needsUpdateOrInstall =
      (originalBasket === undefined && basket !== undefined) ||
      basket.language !== originalBasket.language ||
      basket.channel !== originalBasket.channel ||
      hasImmigratedPerson(basket) !== hasImmigratedPerson(originalBasket) ||
      getDistributionPartnerNumber(basket.distributionPartners) !==
        getDistributionPartnerNumber(originalBasket.distributionPartners)

    if (needsUpdateOrInstall) {
      await install(basket)
    }
  }

  async function install(basket) {
    const { language, channel } = basket
    const distributionPartnerNumber = getDistributionPartnerNumber(basket.distributionPartners)
    const data = await incentiveAPI.fetchIncentive({
      language,
      channel: channel || CHANNEL.OVP,
      immigrant: hasImmigratedPerson(basket),
      distributionPartnerNumber,
    })

    return setIncentive(data)
  }

  function setIncentive(payload) {
    ReactivityUtil.reAssign(incentive, payload)
    logInfo(['%cINCENTIVE SET', 'color: green', incentive])
    return incentive
  }

  return {
    incentive: readonly(incentive),
    install,
    installIncentiveOnUpdate,
  }
}
