<template>
  <!-- prettier-ignore -->
  <div
    ref="el"
    class="ab-config"
    data-entry-widget-target="persons"
  />
</template>

<script setup>
import { onMounted, ref } from 'vue'

import useABConfig from '@/hooks/useABConfig'

// HOOKS
const { abConfig } = useABConfig()

// DATA
const el = ref()

// METHODS
function updateValues() {
  for (const key in el.value.dataset) {
    if (el.value.dataset[key]) {
      let configValue = el.value.dataset[key]
      switch (el.value.dataset[key]) {
        case 'true':
          configValue = true
          break

        case 'false':
          configValue = false
          break
      }

      abConfig.value[key] = configValue
    }
  }
}

// LIFECYCLE HOOKS
onMounted(() => {
  const observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      if (mutation.type === 'attributes') {
        updateValues()
      }
    })
  })
  observer.observe(el.value, { attributes: true })

  updateValues()
})
</script>

<style scoped>
.ab-config {
  display: contents;
}
</style>
