import { ref } from 'vue'

import masterDataAPI from '@/api/masterData'

import { filterOffices } from '@/components/MedicalSearch/utils/filter'
import { setZoom } from '@/components/MedicalSearch/utils/map'
import { createMarkerHoverCard } from '@/components/MedicalSearch/utils/marker'
import {
  boundsSearchOptions,
  createSearchPayload,
  iSelectedIdInFilteredData,
  transformSearchResponse,
} from '@/components/MedicalSearch/utils/search'
import { ZOOM_DEFAULT } from '@/components/MedicalSearch/config/constants'

// GLOBAL DATA
const data = ref([])
const filteredData = ref([])
const location = ref({})
const center = ref(null)
const filters = ref([])
const initialZoom = ref(ZOOM_DEFAULT)
const zoom = ref(ZOOM_DEFAULT)
const startDate = ref('')
const hoveredId = ref('')
const selectedId = ref('')
const hoverCard = ref(null)
const isSearching = ref(true)
const manualZoomOut = ref(false)
const isFirstCall = ref(true)
const autoScroll = ref(false)

export default function useMapSearch() {
  // METHODS
  function setInitialZoom(value) {
    initialZoom.value = value
  }

  function setHoveredId(id) {
    !id || id === hoveredId.value ? (hoveredId.value = '') : (hoveredId.value = id)
  }

  function setSelectedId(id) {
    !id || id === selectedId.value ? (selectedId.value = '') : (selectedId.value = id)
  }

  function setLocation(newLocation) {
    location.value = newLocation
  }

  function setHoveredMarker(marker) {
    setHoveredId(marker?.zsrNumber)
    hoverCard.value = marker ? createMarkerHoverCard(marker) : null
  }

  function onFilter(newFilters) {
    setSelectedId()
    filters.value = newFilters
    autoScroll.value = false
    filteredData.value = filterOffices(data.value, newFilters)
  }

  function onMarkerSelect(marker) {
    if (marker.zsrNumber === selectedId.value) {
      setSelectedId()
    } else {
      autoScroll.value = true
      setSelectedId(marker.zsrNumber)
    }
  }

  function onZoom(newZoom) {
    if (newZoom < zoom.value) {
      manualZoomOut.value = true
    }
    zoom.value = setZoom(newZoom)
  }

  function resetData() {
    data.value = filteredData.value = []
  }

  function resetFilter() {
    filters.value = []
  }

  async function searchByZipCode(product, limit) {
    let options = { zipCode: location.value?.zipCode }
    await $_mapSearch_search(options, product, limit)
  }

  async function searchByBounds(bounds, product, limit) {
    const options = boundsSearchOptions(bounds)
    await $_mapSearch_search(options, product, limit)
  }

  async function $_mapSearch_search(options, product, limit, callback) {
    $_mapSearch_beforeSearch()

    const payload = createSearchPayload(product, {
      options,
      limit,
      contractStartDate: startDate.value,
    })

    try {
      const response = await masterDataAPI.findProviders(payload)
      const responseData = transformSearchResponse(response)
      data.value = responseData
      filteredData.value = filterOffices(responseData, filters.value)

      manualZoomOut.value = false

      if (!iSelectedIdInFilteredData(filteredData.value, selectedId.value)) {
        setSelectedId()
      }

      if (callback) {
        await callback()
      }
    } catch (err) {
      console.error(err)
    }

    // no callback means it is the final search call and not the first nested call for the $_mapSearch_search in searchByZipCode
    // Needed to prevent the loader to stop to early and the list to show the no entry message
    if (!callback) {
      $_mapSearch_afterSearch()
    }
  }

  function $_mapSearch_beforeSearch() {
    isSearching.value = true
    data.value = filteredData.value = []
  }

  function $_mapSearch_afterSearch() {
    isSearching.value = false
    isFirstCall.value = false
  }

  return {
    autoScroll,
    center,
    data,
    filteredData,
    hoverCard,
    hoveredId,
    isFirstCall,
    isSearching,
    location,
    manualZoomOut,
    onFilter,
    onMarkerSelect,
    onZoom,
    resetData,
    resetFilter,
    selectedId,
    searchByBounds,
    searchByZipCode,
    setHoveredId,
    setHoveredMarker,
    setInitialZoom,
    setLocation,
    setSelectedId,
    startDate,
    zoom,
  }
}
