export default {
  sanitas: {
    postAddress: 'Sanitas Health Insurance,<br>Head Office<br>Jägergasse 3<br>8021 Zurich',
    phoneNumber: '0800 22 88 44',
  },
  form: {
    submit: 'Select',
    title: 'Search doctor or practice',
    input: 'Doctor (surname) or HMO practice',
    zipCode: {
      title: 'Postcode',
      placeholder: 'e.g. 8051',
    },
    errors: {
      keyRequired: 'Please complete this field.',
      noResults: 'Please enter the (correct) postcode.',
      serviceNotAvailable: 'An error has occurred. Please try again later.',
    },
    filters: {
      title: 'Filter criteria',
      caremed: 'CareMed basic insurance',
      netmed: 'NetMed basic insurance',
      patients: 'Admitting new patients',
      name: 'Doctor name',
    },
  },
  type: {
    caremed: 'CareMed',
    medbase: 'Medbase',
    netmed: 'NetMed',
    patients: 'New patients',
  },
  states: {
    all: 'Accepting patients.',
    children: 'Accepting only children.',
    familyMembers: 'Only accepts family members.',
    nobody: 'Accepting no new patients at this time.',
    notEligible: 'Unclear',
    unknown: 'This doctor has not notified whether they are accepting new patients or not.',
  },
  toggle: {
    map: 'Map',
    list: 'List',
  },
  messages: {
    emptyResults: {
      text: 'No doctor found near you. Please change the location or the product',
      link: 'Change Product',
    },
    noResults: 'No doctor found. Please try again.',
    moreResults:
      'More results were found than can be displayed. Please adjust the search criteria if necessary. You can also search directly for a doctor or HMO practice.',
    confirm: {
      office: {
        title: 'Practice is not admitting any new patients',
        description: 'You can select this practice if you are already a patient there or find another practice.',
        cancel: 'Cancel',
        confirm: 'OK',
      },
      product: {
        title: 'This plan {currentProduct} is not available in combination with this doctor.',
        description: 'You can choose the plan {nextProduct} or select another doctor.',
        cancel: 'Back',
        confirm: 'Choose {nextProduct}',
      },
    },
  },
  reductions: {
    reduction: 'Reduction',
    title: 'Different discounts',
    text: 'All family doctors in the NetMed basic insurance model are assigned to one of three discount variants.',
    note: 'Note: the discounts shown are valid from 1 January 2021.',
    level: {
      lowest: 'lowest premium discount',
      medium: 'medium premium discount',
      highest: 'highest premium discount',
    },
  },
}
  