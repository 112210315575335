export const fabActions = {
  CALENSO_ROUTE: 'calensoRoute',
  OPEN_CHAT: 'openChat',
}

export const fabEntryName = {
  ALVA: 'alva',
  APPOINTMENT: 'appointment',
  CONTACT: 'contact',
}

export function FabEntry({ title = '', description = '', links = [new FabLink()] }) {
  this.description = description
  this.links = links
  this.title = title
}

export function FabLink({ id = '', name = '', url = '', target = '_blank', action = '', icon = undefined } = {}) {
  this.action = action
  this.icon = icon
  this.id = id
  this.name = name
  this.target = target
  this.url = url
}

const t = {
  alva: 'fab.entries.alva',
  appointment: 'fab.entries.appointment',
  assistance: 'fab.entries.assistance',
}

const alvaLink = [
  new FabLink({
    id: 'alva',
    name: `${t.alva}.links.alva.name`,
    target: '_self',
    url: `${t.alva}.links.alva.url`,
  }),
]

const dotcomAppointmentLink = [
  new FabLink({
    id: 'appointment',
    name: `${t.appointment}.links.book.name`,
    url: `${t.appointment}.links.book.url`,
  }),
]

const calensoAppointmentLinks = [
  new FabLink({
    action: { name: fabActions.CALENSO_ROUTE, param: 'video' },
    icon: 'video',
    id: 'video',
    name: `${t.appointment}.links.book.video`,
  }),
  new FabLink({
    action: { name: fabActions.CALENSO_ROUTE, param: 'personal' },
    icon: 'user-group',
    id: 'personal',
    name: `${t.appointment}.links.book.personal`,
  }),
  new FabLink({
    action: { name: fabActions.CALENSO_ROUTE, param: 'callback' },
    icon: 'user-headset',
    id: 'callback',
    name: `${t.appointment}.links.book.callback`,
  }),
]

export const fabEntries = (isWww, hideAide = false, existingCustomer = false) => {
  const alva = new FabEntry({
    description: `${t.alva}.description`,
    links: alvaLink,
    title: `${t.alva}.title`,
  })

  const appointment = new FabEntry({
    description: isWww ? `${t.appointment}.description.dotcom` : `${t.appointment}.description.dvp`,
    links: isWww ? dotcomAppointmentLink : calensoAppointmentLinks,
    title: `${t.appointment}.title`,
  })

  const contact = new FabEntry({
    description: `${t.assistance}.description`,
    title: `${t.assistance}.title`,
  })

  return {
    [fabEntryName.ALVA]: !hideAide && !existingCustomer ? alva : null,
    [fabEntryName.APPOINTMENT]: !existingCustomer ? appointment : null,
    [fabEntryName.CONTACT]: contact,
  }
}
