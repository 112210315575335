<template>
  <!-- prettier-ignore -->
  <div class="entry">
    <the-typography
      class="entry__title"
      tag="h4"
      type="heading06"
      v-text="$props.titleLabel"
    />

    <the-typography
      tag="span"
      type="bodyMediumLong"
      v-text="$props.description"
    />

    <the-typography type="bodyMediumLong">
      <div class="entry__links">
        <a
          v-for="link in $props.links"
          :key="link.name"
          class="entry__link"
          :class="{'entry__link--disabled': link.disabled}"
          data-track-as="fab-appointment"
          :data-track-text="$t(link.name)"
          :href="link.url ? $t(link.url) : null"
          :target="link.url ? link.target : null"
          @click="e => handleLinkClick(e, link)"
        >
          <the-icon
            class="entry__icon"
            art="light"
            :name="`${link.icon || 'arrow-right'}`"
          />
          <span v-html="$t(link.name)" />
        </a>
      </div>
    </the-typography>
  </div>
</template>

<script setup>
// INIT
const emit = defineEmits(['action'])
defineProps({
  description: {
    type: String,
    required: true,
  },
  links: {
    type: Array,
    default: () => [],
  },
  titleLabel: {
    type: String,
    required: true,
  },
})

// METHODS
function handleLinkClick(event, link) {
  if (link.action) {
    event.preventDefault()
  }
  emit('action', link.action)
}
</script>

<style name="mobile" scoped>
.entry {
  display: flex;
  flex-direction: column;
}

.entry {
  .entry__title {
    margin: 0;
  }

  .entry__links {
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .entry__link {
    display: grid;
    grid-template-columns: 30px 1fr;
    align-items: center;
    font-size: inherit;

    &:hover {
      border-bottom: none;
    }
  }

  .entry__link--disabled {
    pointer-events: none;
    color: var(--c-secondary-neutral-2);
  }

  .entry__link--disabled .entry__icon {
    cursor: default;
    color: var(--c-secondary-neutral-2);
  }
}
</style>
