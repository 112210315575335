<template>
  <!-- prettier-ignore -->
  <form
    ref="formRef"
    v-form-tracker="{triggerCancel, triggerInteraction}"
    v-bind="$attrs"
    novalidate
    :name="$props.name"
    @submit.prevent="debouncedSubmit"
  >
    <slot/>
  </form>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from 'vue'

import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'

import events$ from '@/services/Events'
import vFormTracker from '@/directives/FormTracker'

import { DEFAULT_DELAY_TIME, DEFAULT_FORM_DEBOUNCE_TIME } from '@/config/constants'
import { EVENT_FORM } from '@/config/events'

// INIT
const emit = defineEmits(['submit', 'cancel'])
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  // trigger submit, even if form is not dirty
  noChangeDetection: {
    type: Boolean,
    default: false,
  },
  trackingDisabled: {
    type: Boolean,
    default: false,
  },
  validator: {
    type: Object,
    required: true,
  },
})

// DATA
const formRef = ref(undefined)
const debouncedSubmit = debounce(submit, DEFAULT_FORM_DEBOUNCE_TIME)

// COMPUTED
const payload = computed(() => {
  return {
    errors: yieldErrors(),
    form: props.name,
  }
})

// METHODS
async function handleAction() {
  const isDirty = cloneDeep(props.validator.$anyDirty)

  props.validator.$touch()

  const isValid = await props.validator.$validate()
  if (isValid) {
    // if dirty (or change detection has been disabled). trigger submit
    if (props.noChangeDetection || isDirty) {
      triggerSubmit(isDirty)
    } else {
      triggerCancel(isDirty)
    }
  } else {
    await scrollToError()
    triggerError(false)
  }
}

/**
 * Rejects if invalid after pending validators finished
 *
 * @param {VuelidateState} validator
 * @returns {Promise}
 */
function pendingValidate(validator, _field = '$invalid') {
  return new Promise((resolve, reject) => {
    if (validator.$anyError || !validator.$pending) {
      return validator[_field] ? reject(validator) : resolve(validator)
    }
    const poll = setInterval(() => {
      if (!validator.$pending) {
        clearInterval(poll)
        return validator[_field] ? reject(validator) : resolve(validator)
      }
    }, DEFAULT_DELAY_TIME / 5)
  })
}

async function scrollToError() {
  await nextTick()
  const query =
    '.checkbox--invalid, .date-span--invalid, .dropdown--invalid, .gender--invalid, .group--invalid, .input--invalid, .month-year--invalid, .radio--invalid, .textarea--invalid, .zipchooser--invalid'

  // if not found withing form-bounds, search on root-level
  let element = formRef.value.querySelector(query)
  if (!element) {
    element = document.querySelector(query)
  }

  element?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  })
}

function submit() {
  pendingValidate(props.validator).then(handleAction).catch(handleAction)
}

function triggerCancel(dirty) {
  if (!props.trackingDisabled) {
    events$.emit(EVENT_FORM.CANCELED, payload.value)
  }

  props.validator.$reset()
  emit('cancel', { dirty })
}

function triggerError(touch = true) {
  if (touch) props.validator.$touch()
  if (!props.trackingDisabled) events$.emit(EVENT_FORM.ERROR, payload.value)
}

function triggerInteraction(fieldName) {
  if (!props.trackingDisabled) {
    events$.emit(EVENT_FORM.INTERACTED, {
      ...payload.value,
      fieldName,
    })
  }
}

function triggerSubmit(dirty) {
  if (!props.trackingDisabled) events$.emit(EVENT_FORM.SUBMITTED, payload.value)

  emit('submit', { dirty })
}

function yieldErrors() {
  if (props.validator.$errors.length === 0) return ''

  return `${props.validator.$errors
    .map(err => {
      return `${err.$property}:${err.$validator}`
    })
    .join(',')};`
}

// LIFECYCLE HOOKS
onMounted(() => {
  if (!props.trackingDisabled) events$.emit(EVENT_FORM.INIT, payload.value)
})

// EPILOGUE
defineExpose({ submit })
</script>
