<template>
  <the-typography
    class="mos-info-card"
    type="bodyLargeShort"
  >
    <template v-if="$props.options.address">
      <div
        class="mos-info-card__address"
        v-html="getTranslation($props.options.address)"
      />
      <the-typography
        tag="a"
        type="bodyLargeShort"
        :href="'tel:' + phoneNumberInfo"
        v-text="phoneNumberInfo"
      />
    </template>
    <template v-else> {{ name }}<br />{{ streetInfo }}<br />{{ townInfo }} </template>
  </the-typography>
</template>

<script setup>
import { computed } from 'vue'

import useMedicalOffice from '@/hooks/useMedicalOffice'

// HOOKS
const { getTranslation, hasLabel } = useMedicalOffice()

// INIT
const props = defineProps({
  options: {
    type: Object,
    required: true,
  },
})

// COMPUTED
const name = computed(() => {
  const { firstName, lastName } = props.options
  return stringJoin([firstName, lastName])
})

const phoneNumberInfo = computed(() => {
  const { phoneNumber } = props.options
  return hasLabel(phoneNumber) ? getTranslation(phoneNumber) : phoneNumber
})

const streetInfo = computed(() => {
  const { street, houseNumber } = props.options
  return stringJoin([street, houseNumber])
})

const townInfo = computed(() => {
  const { zipCode, town } = props.options
  return stringJoin([zipCode, town])
})

// METHODS
function stringJoin(strings) {
  return strings.join(' ').trim()
}
</script>

<style scoped>
.mos-info-card {
  position: relative;
  box-shadow: none;
  border-radius: 0;
  background-color: var(--c-primary-neutral-1);
  color: var(--c-primary-neutral-3);
  padding: 10px;
  min-width: 225px;
}

.mos-info-card__address {
  margin-bottom: 20px;
}
</style>
