<template>
  <!-- prettier-ignore -->
  <the-modal
    v-model="data.show"
    locked
  >
    <div class="confirmation">
      <the-message
        class="confirmation__message"
        :severity="data.severity"
        :text="data.label"
        :title-label="data.title"
      />
    </div>
    <template #secondary>
      <Form.Button
        secondary
        :text="data.cancelText"
        @click="cancel"
      />
    </template>
    <template #primary>
      <Form.Button
        primary
        :text="data.confirmText"
        @click="confirm"
      />
    </template>
  </the-modal>
</template>

<script setup>
import { onBeforeMount, onBeforeUnmount, reactive } from 'vue'

import events$ from '@/services/Events'

import useI18n from '@/hooks/useI18n'

import * as Form from '@/components/Form'

import { EVENT_CONFIRMATION_MODAL_OPEN } from '@/config/events'

// HOOKS
const { t } = useI18n()

// DATA
const data = reactive({
  cancelText: null,
  confirmText: null,
  label: null,
  reject: null,
  resolve: null,
  severity: null,
  show: false,
  title: null,
})

// METHODS
async function cancel() {
  await close()

  return data.reject()
}

function close() {
  data.show = false
  return Promise.resolve()
}

async function confirm() {
  await close()

  return data.resolve()
}

function doOpenEvent(payload) {
  const { message, buttons, callbacks } = payload

  data.cancelText = buttons?.cancelText || t('form.cancel')
  data.confirmText = buttons?.confirmText || t('form.ok')

  data.label = message?.label
  data.title = message?.title
  data.severity = message?.severity
  data.resolve = callbacks?.resolve
  data.reject = callbacks?.reject

  data.show = true
}

// LIFECYCLE HOOKS
onBeforeMount(() => {
  events$.on(EVENT_CONFIRMATION_MODAL_OPEN, doOpenEvent)
})
onBeforeUnmount(() => {
  events$.off(EVENT_CONFIRMATION_MODAL_OPEN, doOpenEvent)
})
</script>

<style name="mobile" scoped>
.confirmation__message {
  padding: 20px;
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .confirmation__message {
    padding: 30px;
  }
}
</style>
