<template>
  <!-- prettier-ignore -->
  <transition name="pop">
    <div
      v-if="$props.isOpen"
      v-sticky-basket="stickyBasketOptions"
      class="overlay"
      :class="{'overlay--www': isWww}"
      :sticky-basket-desktop="browser.isDesktop"
    >
      <div
        class="overlay__header"
        :class="{'overlay__header--separated': showTopSeparation}"
      >
        <the-typography
          tag="span"
          type="heading05"
          v-html="$props.message"
        />
        <button
          class="header__close"
          aria-label="Close Basket Widget"
          type="button"
          @click="handleClose"
        >
          <the-icon
            class="header__close-icon"
            art="light"
            name="xmark"
          />
        </button>
      </div>

      <basket-widget-person-list
        ref="overlayContentRef"
        class="overlay__content"
        :persons="$props.persons"
        :readonly="$props.readonly"
        :show-details="$props.showDetails"
      />

      <div
        class='overlay__footer'
        :class="{'overlay__footer--separated': showBottomSeparation}">
        <div
          v-if="!hasSelectedProducts"
          class="footer__no-product"
        >
          <the-typography
            type="bodyLargeLong"
          >
            {{ isBasketWidget ? $t('basket.noProductsAddSome') : $t('basket.noProducts') }}
          </the-typography>
        </div>

        <div
          v-else
          class="footer__total"
        >
          <the-typography type="heading01">
            <product-price
              :price="$props.total"
              :suffix="$t(priceSuffix)"
            />
          </the-typography>
        </div>

        <basket-widget-action
          v-if="hasAction"
          :has-persons="hasPersons"
          @click="$emit('action-click')"
        />

        <Form.Button
          v-if="browser.isDesktop && $props.persons.length > 0 && hasSelectedProducts"
          plain
          :text="$props.showDetails ? $t('basket.showLess'): $t('basket.showMore')"
          @click="$emit('toggle-details')"
        />
      </div>
    </div>
  </transition>
</template>

<script setup>
import { computed, nextTick, ref, watch } from 'vue'

import VStickyBasket from '@/directives/StickyBasket'

import useApplication from '@/hooks/useApplication'
import useBrowser from '@/hooks/useBrowser'
import usePerson from '@/hooks/usePerson'
import useProductPersons from '@/hooks/useProductPersons'
import useStatus from '@/hooks/useStatus'

import * as Form from '@/components/Form'
import BasketWidgetAction from '@/components/BasketWidget/Container/Action'
import BasketWidgetPersonList from '@/components/BasketWidget/Container/PersonList'
import ProductPrice from '@/components/Product/ProductPrice'

// HOOKS
const { browser } = useBrowser()
const { hasSelectedProducts } = useProductPersons()
const { isPersonalDataValid } = useStatus()
const { isWww } = useApplication()

// INIT
const emit = defineEmits(['action-click', 'close', 'toggle-details'])
const props = defineProps({
  isManuallyOpened: {
    type: Boolean,
    default: false,
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
  message: {
    type: String,
    default: '',
  },
  persons: {
    type: Array,
    required: true,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  showDetails: {
    type: Boolean,
    default: false,
  },
  total: {
    type: Number,
    default: 0,
  },
})

// DATA
let bottomSeparationObserver, topSeparationObserver
const overlayContentRef = ref(null)
const showBottomSeparation = ref(false)
const showTopSeparation = ref(false)

// COMPUTED
const hasAction = computed(() => {
  return isBasketWidget.value || (hasPersons.value && props.total > 0)
})

const hasPersons = computed(() => {
  const { persons } = usePerson()
  return isPersonalDataValid(persons.value)
})

const isBasketWidget = computed(() => {
  return isWww.value
})

const priceSuffix = computed(() => {
  return browser.isDesktop ? 'product.amountMonthly' : 'product.currency'
})

const stickyBasketOptions = computed(() => {
  return {
    isManuallyOpened: props.isManuallyOpened,
    onClose: handleClose,
  }
})

// METHODS
function handleClose() {
  emit('close')
}

function handleContentSeparation(contentEl) {
  const topContent = contentEl.firstElementChild
  const bottomContent = contentEl.lastElementChild

  const topContentCb = entries => {
    showTopSeparation.value = entries[0].intersectionRatio !== 1
  }
  const bottomContentCb = entries => {
    showBottomSeparation.value = entries[0].intersectionRatio !== 1
  }

  const createIntersectionObserver = (el, cb) => {
    let observer = new IntersectionObserver(cb, {
      root: contentEl,
      threshold: 1,
    })
    observer.observe(el)
    return observer
  }

  topSeparationObserver = createIntersectionObserver(topContent, topContentCb)
  bottomSeparationObserver = createIntersectionObserver(bottomContent, bottomContentCb)
}

function resetSeparation() {
  showTopSeparation.value = false
  showBottomSeparation.value = false
  topSeparationObserver?.disconnect()
  bottomSeparationObserver?.disconnect()
}

function updateContentRendering() {
  if (!props.isOpen || !props.showDetails) {
    resetSeparation()
    return
  }

  // Wait for overlayContentRef to be ready in DOM
  nextTick(() => {
    const contentEl = overlayContentRef.value?.$el
    if (!contentEl || contentEl.scrollHeight < contentEl.clientHeight) {
      resetSeparation()
      return
    }

    handleContentSeparation(contentEl)
  })
}

// WATCHERS
watch(() => props.showDetails, updateContentRendering)
watch(() => props.isOpen, updateContentRendering)
</script>

<style name="animations" scoped>
@keyframes pop {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.pop-enter-active {
  animation: pop 0.3s;
}

.pop-leave-active {
  animation: pop 0.3s reverse;
}
</style>

<style name="mobile" scoped>
.overlay {
  position: fixed;
  top: 57px;
  left: 0;
  right: 0;
  z-index: 9000;
  background-color: var(--c-primary-neutral-3);
  border-top: 2px solid var(--c-primary-neutral-2);
  border-bottom: 1px solid var(--c-secondary-neutral-3);
  transform-origin: top right;
  transition: top 0.25s ease-in-out;
}

.overlay__sticky {
  top: 0;
}

.overlay--www {
  top: 45px;
  z-index: 801;

  &.overlay__sticky-desktop {
    top: 175px;
  }
}

.overlay__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 21px 16px 16px;
}

.header__close {
  background-color: inherit;
  border: none;
  cursor: default;
  z-index: 9999;
  padding: 0;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  &:focus {
    outline: none;
  }
}

.header__close-icon {
  font-size: 16px;
  cursor: pointer;
  color: var(--c-primary-neutral-1);
}

.overlay__content {
  padding: 16px;
}

.overlay__footer {
  padding: 16px;
}

.footer__no-product {
  margin: 16px 0;
}

.footer__total {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  margin-bottom: 10px;
}
</style>

<style name="www-mobile" scoped>
@media (--sm) {
  .overlay--www {
    top: 65px;
  }
  .overlay__sticky {
    top: 0;
  }
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .overlay {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 62px);
    position: absolute;
    top: 30px;
    left: initial;
    right: -8px;
    width: 400px;
    border: 1px solid var(--c-secondary-neutral-3);
    transition: none;
  }
  .overlay__sticky-desktop {
    top: 0;
    left: initial;
    right: 24px;
    position: fixed;
    max-height: 100vh;
  }

  .button.plain {
    float: right;
    padding: 16px 0 0 0;
    color: var(--c-primary-color-3);
  }

  .overlay__header {
    padding: 16px 18px 16px 16px;
  }

  .overlay__header--separated {
    box-shadow:
      rgba(0, 0, 0, 0.1) 0 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0 2px 4px -1px;
  }

  .overlay__content {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .overlay__footer--separated {
    box-shadow:
      rgba(0, 0, 0, 0.1) 0 -4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0 -2px 4px -1px;
  }

  .header__close {
    top: 28px;
  }
}
</style>
