<template>
  <!-- prettier-ignore -->
  <div v-show="showFab">
    <fab-button
      :has-bottom-gap="isWww"
      :is-toggle-active="showFabCard"
      @click="toggleFabCard(false)"
    />
    <fab-card
      :entries="entries"
      :show-card="showFabCard"
      @close="toggleFabCard"
    />
  </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import { events$ } from '@/services'

import basketStore from '@/store/basket'
import partnerStore from '@/store/partner'
import useApplication from '@/hooks/useApplication'
import useChat from '@/hooks/useChat'

import FabButton from '@/components/Fab/FabButton'
import FabCard from '@/components/Fab/FabCard'
import { fabEntries } from '@/components/Fab/entries'

import { COLLECTIVE_CHANNEL } from '@/config/constants'
import { EVENT_DOTCOM_SURVEY_WEBSITE, EVENT_FAB, CUSTOM_EVENT_CHAT } from '@/config/events'

// HOOKS
const { isWww } = useApplication()
const { initChat } = useChat()

// INIT
let route
if (!isWww.value) {
  route = useRoute()
}
initChat()

// DATA
const showFab = ref(true)
const showFabCard = ref(false)

// COMPUTED
const entries = computed(() => {
  const hideAide =
    route?.meta?.aide ||
    partnerStore.partner.collectiveContractConfig?.channel === COLLECTIVE_CHANNEL.SCPC || // if it's COLLECTIVE // @TODO might be same as partnerStore.isCollective.value?
    partnerStore.isBroker.value ||
    partnerStore.isMobileDistributor.value // if it's a broker/distributor (like privateassekuranz)
  return fabEntries(isWww.value, hideAide, basketStore.basket.existingCustomer)
})

// METHODS
function displayFab() {
  showFab.value = true
}

function hideFab() {
  showFab.value = false
}

function toggleFabCard(interacted) {
  showFabCard.value = !showFabCard.value

  if (showFabCard.value) {
    events$.emit(EVENT_FAB.OPENED)
  }

  // FLEX-1064 survey trigger in dvp and dotcom
  if (!showFabCard.value && !interacted) {
    events$.emit(EVENT_DOTCOM_SURVEY_WEBSITE)
  }
}

// LIFECYCLE HOOKS
onMounted(() => {
  window.addEventListener(CUSTOM_EVENT_CHAT.CONVERSATION_STARTED, hideFab)
  window.addEventListener(CUSTOM_EVENT_CHAT.CLOSE, displayFab)

  events$.on(EVENT_FAB.OPEN, () => {
    if (!showFabCard.value) toggleFabCard()
  })
})
onBeforeUnmount(() => {
  window.removeEventListener(CUSTOM_EVENT_CHAT.CONVERSATION_STARTED, hideFab)
  window.removeEventListener(CUSTOM_EVENT_CHAT.CLOSE, displayFab)
})
</script>
