<template>
  <!-- prettier-ignore -->
  <div
    v-unblu-protected
    class="basketForm"
  >
    <div class="basketForm__title">
      <the-typography
        type="displaySmall"
        v-text="$t(`basket.modal.${title}`)"
      />
    </div>

    <the-message
      v-if="textDisabled"
      class="basketForm--message"
      severity="error"
      :text="textDisabled"
    />

    <p
      v-else
      v-html="$t(`basket.modal.${description}`)"
    />

    <Form.Form
      class="basketForm-form"
      no-change-detection
      tracking-disabled
      :name="formName"
      :validator="v"
      @cancel="$emit('cancel')"
      @submit="submit"
    >
      <template v-if="$props.tiny">
        <Form.Input
          v-model="formData.email"
          class="form__input"
          autocomplete="email"
          name="email"
          required
          theme="dark"
          type="email"
          :placeholder="$t('person.email')"
          :title-label="$t('person.email')"
          :validate="v.email"
        />
      </template>
      <template v-else>
        <Form.Input
          v-model="formData.firstName"
          class="form__input"
          autocomplete="given-name"
          name="firstName"
          theme="light"
          :placeholder="$t('person.firstname')"
          :title-label="$t('person.firstname')"
          :validate="v.firstName"
        />

        <Form.Input
          v-model="formData.lastName"
          class="form__input"
          autocomplete="family-name"
          name="lastName"
          required
          theme="light"
          :placeholder="$t('person.lastname')"
          :title-label="$t('person.lastname')"
          :validate="v.lastName"
        />

        <Form.PhoneInput
          v-model="formData.mobile"
          class="form__input"
          name="mobile"
          required
          theme="light"
          :delay="10000"
          :placeholder="$t('person.mobile')"
          :title-label="$t('person.mobile')"
          :validate="v.mobile"
        />

        <Form.Input
          v-model="formData.email"
          class="form__input"
          autocomplete="email"
          name="email"
          required
          theme="light"
          :placeholder="$t('person.email')"
          :title-label="$t('person.email')"
          :validate="v.email"
        />
      </template>

      <div class="form__actions">
        <Form.Button
          v-if="$props.cancelable"
          secondary
          type="cancel"
          :text="$t('form.cancel')"
        />

        <Form.Button
          primary
          type="submit"
          :disabled="!!textDisabled"
          :has-loading-indicator="v.$dirty"
          :text="$t(saveButtonLabel)"
        />
      </div>
    </Form.Form>
  </div>
</template>

<script setup>
import { computed, reactive } from 'vue'
import useVuelidate from '@vuelidate/core'

import validations from '@/validations/basket_save_form'

import basketStore from '@/store/basket'
import useI18n from '@/hooks/useI18n'
import useStatus from '@/hooks/useStatus'

import * as Form from '@/components/Form'

// HOOKS
const { t } = useI18n()
const { basketIsValid } = useStatus()

// INIT
const emit = defineEmits(['save', 'cancel'])
const props = defineProps({
  cancelable: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Object,
    default: () => {},
  },
  isSaveWithoutApplicationData: {
    type: Boolean,
    default: false,
  },
  /**
   * Show only email input field and send emailType 'OFFER_REENTRY_WITHOUT_EGATE'
   */
  tiny: {
    type: Boolean,
    default: false,
  },
})

// DATA
const formData = reactive(props.data)

// COMPUTED
const description = computed(() => {
  if (props.isSaveWithoutApplicationData) {
    return 'basketScreenModalText'
  }

  return props.tiny ? 'basketSavingInformation' : 'basketOfferteInformation'
})

const disabled = computed(() => {
  return !props.tiny && !basketIsValid(basketStore.basket)
})

const formName = computed(() => {
  return props.tiny ? 'save_form_tiny' : 'save_form'
})

const saveButtonLabel = computed(() => {
  return props.tiny || props.isSaveWithoutApplicationData ? 'form.save' : 'form.send'
})

const textDisabled = computed(() => {
  return disabled.value ? t(`basket.modal.basketInvalidProductsSaveDisabled`) : false
})

const title = computed(() => {
  if (props.isSaveWithoutApplicationData) {
    return 'basketScreenModalTitle'
  }

  return props.tiny ? 'basketSavingTitle' : 'basketOfferteTitle'
})

// METHODS
function submit() {
  emit('save', formData)
}

// VALIDATION
const v = useVuelidate(props.tiny ? validations.tiny : validations.normal, formData, { $lazy: true })
</script>

<style name="mobile" scoped>
.basketForm {
  display: flex;
  flex-direction: column;
}

.basketForm__title {
  color: var(--c-primary-neutral-1);
  margin-bottom: 20px;
}

.basketForm-form {
  flex: 1 1 auto;

  .form__input {
    margin-bottom: 20px;
  }
}

.basketForm--message {
  margin-bottom: 20px;
}

.form__actions {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .basketForm-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .form__actions {
      align-self: flex-end;
      flex-direction: row;

      button {
        min-width: 200px;
      }
    }
  }
}
</style>
