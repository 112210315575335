<template>
  <the-typography
    type="bodyLargeShort"
    :class="classes"
  >
    <div class="filters-group__title">
      {{ getTranslation('form.filters.title') }}
      <div
        v-show="isMobile"
        :class="toggleIconClasses"
      >
        <the-icon
          art="solid"
          name="chevron-up"
          @click="toggle"
        />
      </div>
    </div>

    <div
      v-show="show"
      class="filters-group__content"
    >
      <Form.Input
        v-model="doctorName"
        ignore-update-on-blur
        name="doctorName"
        theme="dark"
        :delay="0"
        :placeholder="getTranslation('form.filters.name')"
        :title-label="getTranslation('form.filters.name')"
        @update:modelValue="handleInputChange"
      />
      <Form.Checkbox
        v-model="receivePatients"
        class="filters-group__checkbox"
        name="receivePatients"
        :title-label="getTranslation('form.filters.patients')"
        @update:modelValue="handleCheckboxChange"
      />
    </div>
  </the-typography>
</template>

<script setup>
import { computed, ref } from 'vue'

import useBrowser from '@/hooks/useBrowser'
import useMedicalOffice from '@/hooks/useMedicalOffice'

import * as Form from '@/components/Form'

// HOOKS
const { browser } = useBrowser()
const { getTranslation } = useMedicalOffice()

// INIT
const emit = defineEmits(['filter'])

// DATA
const currentFilters = ref([])
const doctorName = ref(null)
const isDesktop = browser.isDesktop
const isExpanded = ref(true)
const isMobile = !isDesktop
const receivePatients = ref(false)

// COMPUTED
const classes = computed(() => {
  return {
    'filters-group': true,
    'filters-group--is-mobile': isMobile,
  }
})

const show = computed(() => {
  return isExpanded.value || isDesktop
})

const toggleIconClasses = computed(() => {
  return {
    'filters-group__toggle': true,
    'filters-group__toggle--rotate': !isExpanded.value,
  }
})

// METHODS
function handleCheckboxChange(isChecked) {
  if (isChecked) {
    currentFilters.value.push({ name: 'PATIENTS', value: true })
  } else {
    currentFilters.value = currentFilters.value.filter(f => f.name !== 'PATIENTS')
  }
  emit('filter', currentFilters.value)
}

function handleInputChange(value) {
  if (!value) {
    currentFilters.value = currentFilters.value.filter(f => f.name !== 'NAME')
    emit('filter', currentFilters.value)
  } else if (value.length >= 3) {
    const inputIndex = currentFilters.value.findIndex(f => f.name === 'NAME')
    if (inputIndex === -1) {
      currentFilters.value.push({ name: 'NAME', value: value })
    } else {
      currentFilters.value[inputIndex].value = value
    }
    emit('filter', currentFilters.value)
  }
}

function toggle() {
  isExpanded.value = !isExpanded.value
}
</script>

<style scoped>
.filters-group {
  padding: 20px 15px 16px 15px;
  margin-bottom: 8px;
}

.filters-group__title {
  font-weight: bold;
  margin-bottom: 16px;
  position: relative;
}

.filters-group__toggle {
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(0deg);
  transition-duration: 0.3s;
}

.filters-group__toggle--rotate {
  transform: rotate(180deg);
  transition-duration: 0.3s;
}

.filters-group__checkbox {
  margin-top: 16px;
}

.filters-group--is-mobile {
  background: var(--c-primary-neutral-2);
}

@media (--md) {
  .filters-group {
    padding: 0;
    margin: 20px 30px;
  }
}
</style>
