<template>
  <!-- prettier-ignore -->
  <div class="entry">
    <the-typography
      class="entry__title"
      tag="h4"
      type="heading06"
      v-text="$props.titleLabel"
    />

    <the-typography
      tag="span"
      type="bodyMediumLong"
      v-text="$props.description"
    />

    <the-typography type="bodyMediumLong">
      <div class="entry__links">
        <template v-if="contactInfo?.phone?.enabled">
          <a
            class="entry__link"
            :class="{'entry__link--disabled': !contactInfo.phone.active}"
            data-track-as="fab-appointment"
            :data-track-text="contactInfo.phone.nr"
            :href="`tel:${contactInfo.phone.nr}`"
          >
            <the-icon
              class="entry__icon"
              art="light"
              name="phone"
            />
            <span v-html="contactInfo.phone.nr" />
          </a>
        </template>

        <template v-if="contactInfo?.chat?.enabled">
          <a
            class="entry__link"
            :class="{'entry__link--disabled': !contactInfo.chat.active}"
            data-track-as="fab-appointment"
            :data-track-text="'chat'"
            @click="e => handleLinkClick(e, { action: { name: 'openChat' } })"
          >
            <the-icon
              class="entry__icon"
              art="light"
              name="comments"
            />
            <span v-html="$t('fab.entries.assistance.links.chat.name')" />
          </a>
        </template>

        <template v-if="contactInfo?.email">
          <a
            class="entry__link"
            :class="{'entry__link--disabled': !contactInfo.email}"
            data-track-as="fab-appointment"
            :data-track-text="'email'"
            :href="`mailto:${contactInfo.email[selectedLanguage]}`"
          >
            <the-icon
              class="entry__icon"
              art="light"
              name="at"
            />
            <span v-html="contactInfo.email[selectedLanguage]" />
          </a>
        </template>
      </div>
    </the-typography>
  </div>
</template>

<script setup>
import useContact from '@/hooks/useContact'
import useI18n from '@/hooks/useI18n'

// HOOKS
const { contactInfo } = useContact()
const { selectedLanguage } = useI18n()

// INIT
const emit = defineEmits(['action'])
defineProps({
  description: {
    type: String,
    required: true,
  },
  titleLabel: {
    type: String,
    required: true,
  },
})

// METHODS
function handleLinkClick(event, link) {
  if (link.action) {
    event.preventDefault()
  }
  emit('action', link.action)
}
</script>

<style name="mobile" scoped>
.entry {
  display: flex;
  flex-direction: column;
}

.entry__title {
  margin: 0;
}

.entry__links {
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.entry__link {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  font-size: inherit;

  &:hover {
    border-bottom: none;
  }
}

.entry__link--disabled {
  pointer-events: none;
  color: var(--c-secondary-neutral-2);
}

.entry__link--disabled .entry__icon {
  cursor: default;
  color: var(--c-secondary-neutral-2);
}
</style>
