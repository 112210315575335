<template>
  <!-- prettier-ignore -->
  <div :class="classes">
    <div
      v-show="showList"
      class="mos-maps__aside"
    >
      <search-form
        :location="location"
        @search="setLocation"
      />
      <filters-group
        :is-filterable="$props.options.filterableProducts"
        :product="$props.product"
        @filter="onFilter"
      />
      <loading-alt v-if="isLoading" />
      <results-list
        v-else
        :auto-scroll="autoScroll"
        :hovered="hoveredId"
        :product="$props.product"
        :results="sortedAndFilteredResults"
        :selected="selectedId"
        @close="handleResultClose"
      />
    </div>
    <div
      v-show="showMap"
      class="mos-maps__content"
    >
      <google-map
        :hover-card="hoverCard"
        :hovered="hoveredId"
        :is-searching="isSearching"
        :location="location"
        :results="sortedAndFilteredResults"
        :selected="selectedId"
        :zoom="zoom"
        @cluster-click="handleClusterClick"
        @loaded="mapLoaded = true"
        @mouseout="setHoveredMarker"
        @mouseover="setHoveredMarker"
        @reset="resetData"
        @select="handleMarkerSelect"
        @update="mapSearch"
        @zoom="onZoom"
      />
    </div>
    <div class="mos-maps__actions">
      <div
        v-if="!browser.isDesktop"
        class="mos-maps__toggler"
      >
        <medical-office-toggle v-model="hideMap" />
      </div>
      <div class="mos-maps__confirm">
        <Form.Button
          :disabled="!selectedId"
          primary
          type="submit"
          :text="getTranslation('form.submit')"
          @click="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue'

import debounce from 'lodash/debounce'
import map from 'lodash/map'
import pick from 'lodash/pick'

import { ReactivityUtil } from '@/utils/Reactivity'

import useMapSearch from '@/hooks/useMapSearch'
import useMedicalOffice from '@/hooks/useMedicalOffice'
import useBrowser from '@/hooks/useBrowser'

import { createMarkerHoverCard } from '@/components/MedicalSearch/utils/marker'
import { isMaxZoom } from '@/components/MedicalSearch/utils/map'

import * as Form from '@/components/Form'
import FiltersGroup from '@/components/MedicalSearch/components/FiltersGroup'
import GoogleMap from '@/components/MedicalSearch/components/GoogleMap'
import LoadingAlt from '@/components/Container/LoadingAlt'
import MedicalOfficeToggle from '@/components/MedicalSearch/components/MedicalOfficeToggle'
import ResultsList from '@/components/MedicalSearch/components/ResultsList'
import SearchForm from '@/components/MedicalSearch/components/SearchForm'

import { validateDoctor } from '@/components/MedicalSearch/utils/doctor'
import { confirm } from '@/utils/Modal'

// HOOKS
const { browser } = useBrowser()
const {
  autoScroll,
  filteredData,
  hoverCard,
  hoveredId,
  isSearching,
  location,
  onFilter,
  onMarkerSelect,
  onZoom,
  resetData,
  resetFilter,
  searchByBounds,
  searchByZipCode,
  selectedId,
  setHoveredMarker,
  setLocation,
  startDate,
  zoom,
} = useMapSearch()
const { getTranslation } = useMedicalOffice()

// INIT
const emit = defineEmits(['close', 'submit'])
const props = defineProps({
  contractStartDate: {
    type: String,
    required: true,
  },
  limit: {
    type: Number,
    default: 300,
  },
  options: {
    type: Object,
    required: true,
  },
  product: {
    type: [Object, Boolean],
    default: false,
  },
})

// DATA
const hideMap = ref(false)
const mapLoaded = ref(false)

// COMPUTED
const classes = computed(() => {
  return {
    'mos-maps': true,
    'mos-maps--selected': selectedId.value,
    'mos-maps--is-mobile': !browser.isDesktop,
  }
})

const isLoading = computed(() => {
  return isSearching.value || (showMap.value && !mapLoaded.value)
})

const showList = computed(() => {
  return browser.isDesktop || hideMap.value
})

const showMap = computed(() => {
  return browser.isDesktop || !hideMap.value
})

/**
 * FLEX-2584
 * Sanacare needs to be pushed to the top
 */
const sortedAndFilteredResults = computed(() => {
  const key = 'Sanacare'
  const data = ReactivityUtil.clone(filteredData.value)

  return [...data.filter(item => item.lastName.includes(key)), ...data.filter(item => !item.lastName.includes(key))]
})

// METHODS
function handleClusterClick() {
  if (isMaxZoom(zoom.value) && !browser.isDesktop) {
    hideMap.value = true
  }
}

function handleListScroll() {
  const mapEl = document.querySelector('.mos-map')
  if (mapEl) {
    const bounding = mapEl.getBoundingClientRect()
    if (bounding.top < 170) {
      hideMap.value = false
    }
  }
}

function handleMarkerSelect(marker) {
  onMarkerSelect(marker)
  nextTick(() => {
    if (!browser.isDesktop) {
      if (selectedId.value === '') {
        hoverCard.value = null
      } else {
        hoverCard.value = createMarkerHoverCard(marker)
      }
    }
  })
}

/**
 * Handles close event on message when no results are found
 */
function handleResultClose() {
  emit('close')
}

/**
 * Handles submit event to add medical office to product
 */
async function handleSubmit() {
  const selectedOffice = filteredData.value.find(office => office.zsrNumber === selectedId.value)

  if (!selectedOffice) return

  const validityDoctor = validateDoctor(selectedOffice)

  if (!validityDoctor.isValid) {
    const message = validityDoctor.message
    const confirmation = await confirm(
      {
        title: getTranslation(message.title, message.replacement),
        label: getTranslation(message.description, message.replacement),
        severity: 'warning',
      },
      {
        cancelText: getTranslation(message.cancel),
        confirmText: getTranslation(message.confirm, message.replacement),
      }
    )

    if (confirmation) {
      submitOffice(selectedOffice)
    } else {
      selectedId.value = ''
      resetData()
    }
  } else {
    submitOffice(selectedOffice)
  }
}

/**
 * Triggers search for medical offices on map changes
 */
async function mapSearch(mapBounds) {
  await searchByBounds(mapBounds, props.product, props.limit)
}

/**
 * Submits the selected office to add it to the product
 */
function submitOffice(office) {
  const partialOfficeData = partialOffice => pick(partialOffice, ['description', 'avmDefinition', 'discountGroup'])
  const officeData = {
    ...office,
    medicalOfficeNets: map(office.medicalOfficeNets, partialOfficeData),
  }
  emit('submit', officeData)
}

// LIFECYCLE HOOKS
onBeforeUnmount(() => {
  const mapsEl = document.querySelector('.mos-modal__wrapper')
  if (mapsEl) {
    mapsEl.removeEventListener('scroll', handleListScroll)
  }
})

onBeforeMount(() => {
  setLocation(props.options.location)
})

onMounted(async () => {
  resetFilter()
  startDate.value = props.contractStartDate

  await searchByZipCode(props.product, props.limit)

  const mapsEl = document.querySelector('.mos-modal__wrapper')
  if (mapsEl) {
    mapsEl.addEventListener('scroll', debounce(handleListScroll, 100))
  }
})
</script>

<style scoped>
.mos-maps {
  height: 100vh;

  &__content {
    height: 100vh;
    background: var(--c-secondary-neutral-3);
  }

  &__aside {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
    width: auto;
    height: calc(100% - 72px);
  }

  &__actions {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  &__confirm {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 72px;
    padding: 12px 16px;
    background-color: var(--c-primary-neutral-2);

    button {
      width: 100%;
    }
  }

  &__icon {
    position: absolute;
    cursor: pointer;
    display: flex;
    z-index: 1;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    background: var(--c-primary-neutral-3);

    .icon {
      margin: auto;
    }
  }

  &__toggler {
    position: absolute;
    display: flex;
    bottom: 90px;
    width: 100%;

    .toggler {
      margin: 0 auto;
    }
  }

  &--is-loading {
    .mos-maps__content {
      position: absolute;
      left: 5px;
      width: 100vw;
      height: 100vh;
    }
  }

  &--selected {
    .mos-maps {
      &__content,
      &__aside {
        height: calc(100vh - 80px);
      }

      &__toggler {
        bottom: 90px;
      }
    }
  }
}

@media (--md) {
  .mos-maps {
    display: flex;
    flex-direction: row;

    &__content {
      width: calc(100% - 400px);
    }

    &__aside {
      width: 400px;
      height: calc(100% - 72px);
      max-height: inherit;
    }

    &__actions {
      width: 400px;
    }

    &--selected {
      .mos-maps {
        &__content {
          height: 100vh;
        }
      }
    }
  }
}
</style>
