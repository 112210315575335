<template>
  <!-- prettier-ignore -->
  <div
    class="protection"
    :class="{'protection--toggleable': $props.toggleable}"
  >
    <div
      class="protection__head"
      @click="toggle"
    >
      <span
        class="protection__title"
        v-text="$props.titleLabel"
      />
      <the-icon
        v-show="$props.toggleable"
        art="light"
        :name="show ? 'angle-up' : 'angle-down'"
      />
    </div>
    <span
      v-if="show"
      class="protection__bottom"
    >
      <slot/>
    </span>
  </div>
</template>

<script setup>
import { ref } from 'vue'

// INIT
const props = defineProps({
  titleLabel: {
    type: String,
    required: true,
  },
  toggleable: {
    type: Boolean,
    default: false,
  },
})

// DATA
const show = ref(true)

// METHODS
function toggle() {
  if (props.toggleable) {
    show.value = !show.value
  }
}
</script>

<style name="mobile" scoped>
.protection {
  &__title {
    font-size: 12px;
    line-height: 15px;
    color: var(--c-secondary-neutral-1);
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__head {
    display: flex;

    :first-child {
      margin: auto auto auto 0;
    }
    :last-child {
      margin: auto 0 3px auto;
    }
  }

  &__bottom {
    font-size: 12px;
    color: var(--c-primary-neutral-1);
  }

  &--toggleable {
    cursor: pointer;
  }
}
</style>
