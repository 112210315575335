<template>
  <!-- prettier-ignore -->
  <the-form-modal
    v-model="isLoginModalOpen"
    name="loginModal"
    x-only
    @close="close"
  >
    <component
      :is="steps[currentId].component"
      v-bind="steps[currentId].props"
      @back="steps[currentId].back"
      @error="steps[currentId].error"
      @update="steps[currentId].proceed"
    />
  </the-form-modal>
</template>

<script setup>
import { computed, defineAsyncComponent, onMounted, reactive, ref } from 'vue'

import { events$, loading$ } from '@/services'
import { ReactivityUtil } from '@/utils/Reactivity'

import basketAPI from '@/api/basket'
import basketStore from '@/store/basket'
import sessionStore from '@/store/session'
import useApplication from '@/hooks/useApplication'
import useAuth from '@/hooks/useAuth'
import useI18n from '@/hooks/useI18n'
import useToast from '@/hooks/useToast'
import { isOnVitalCampaignPageWhileLogin } from '@/utils/HaX'

import { AuthHelper } from '@/components/Login/steps/kupo/Auth'

import { CHANNEL, LOGIN_ERROR_STATE, LOGIN_OPEN_MODAL, LOGIN_STEP, LOGIN_TYPE, NOTIFICATION } from '@/config/constants'
import { EVENT_LOGIN, EVENT_MODAL, EVENT_PERSONS_FORM_DISPLAYED } from '@/config/events'

// HOOKS
const { getDvpUrl } = useApplication()
const { isExistingCustomerLoggedIn, loginExistingCustomer } = useAuth()
const { t, selectedLanguage } = useI18n()
const { addToast } = useToast()

// DATA
const channel = ref(CHANNEL.OVP)
const currentId = ref()
const email = ref(undefined)
const hideNotification = ref('')
const isLoginModalOpen = ref(false)
const localId = ref(null)
const loginType = ref(undefined)
const mobile = ref(undefined)
const partnerNumber = ref(undefined)
const platform = ref(undefined)
const promptBasket = reactive({})
const selectedPersons = reactive([])
const submitCallback = ref(() => {})
const uid = ref(undefined)

// COMPUTED
const headOfContract = computed(() => {
  const contractOwnerId = promptBasket.contractOwner.personId
  return promptBasket.persons.find(p => p.personId === contractOwnerId)
})

const steps = computed(() => {
  return {
    [LOGIN_STEP.TYPE_SELECTION]: {
      component: defineAsyncComponent(() => import('./steps/TypeSelection')),
      actions: {
        secondary: [
          {
            text: 'login.typeSelection.button.kupo',
            action: () => steps.value[LOGIN_STEP.TYPE_SELECTION].proceed(LOGIN_TYPE.KUPO),
          },
          {
            text: 'login.typeSelection.button.hn',
            action: () => steps.value[LOGIN_STEP.TYPE_SELECTION].proceed(LOGIN_TYPE.HN),
          },
        ],
      },
      back: () => {},
      error: handleError,
      proceed: key => {
        if (key === LOGIN_TYPE.KUPO) {
          const loginStorage = { step: LOGIN_STEP.SUCCESS, componentId: localId.value, startingChannel: channel.value }
          localStorage.setItem(LOGIN_OPEN_MODAL, JSON.stringify(loginStorage))

          let redirectUri = window.location.href
          if (channel.value === CHANNEL.ENTRYWIDGET) {
            redirectUri = getDvpUrl(selectedLanguage.value)
          }

          loginExistingCustomer(redirectUri, selectedLanguage.value)
        } else {
          loginType.value = LOGIN_TYPE.HN
          setCurrent(LOGIN_STEP.AUTH_HN_FORM)
        }
      },
      props: {},
    },
    [LOGIN_STEP.AUTH_HN_FORM]: {
      component: defineAsyncComponent(() => import('./steps/hn/Form')),
      back: () => {
        setCurrent(LOGIN_STEP.TYPE_SELECTION)
      },
      error: handleError,
      proceed: ({ basket: __basket, partnerNumber: __partnerNumber, uid: __uid }) => {
        partnerNumber.value = __partnerNumber
        uid.value = __uid
        ReactivityUtil.reAssign(promptBasket, __basket)
        setCurrent(LOGIN_STEP.SUCCESS)
      },
      props: {},
    },
    [LOGIN_STEP.SUCCESS]: {
      component: defineAsyncComponent(() => import('./steps/success/Form')),
      back: () => {},
      error: handleError,
      proceed: ({ mobile: __mobile, email: __email }) => {
        mobile.value = __mobile || undefined
        email.value = __email || undefined
        setCurrent(LOGIN_STEP.AUTH_PERSONS)
      },
      props: {
        basket: promptBasket,
        loginType: loginType.value,
      },
    },

    [LOGIN_STEP.AUTH_PERSONS]: {
      component: defineAsyncComponent(() => import('./steps/persons/Form')),
      back: () => {},
      error: handleError,
      proceed: async persons => {
        // Create basket
        ReactivityUtil.reAssign(selectedPersons, persons)
        await createBasket()
      },
      props: {
        basketPersons: promptBasket?.persons || [],
        loginType: loginType.value,
      },
    },
    [LOGIN_STEP.ERROR_GENERAL]: {
      component: defineAsyncComponent(() => import('./error/General')),
      back: () => {
        close()
      },
      error: () => {},
      proceed: () => {},
      props: {
        type: 'general',
      },
    },
    [LOGIN_STEP.ERROR_LOCKED]: {
      component: defineAsyncComponent(() => import('./error/General')),
      back: () => {
        close()
      },
      error: () => {},
      proceed: () => {},
      props: {
        type: 'locked',
      },
    },
    [LOGIN_STEP.ERROR_LOGGED_OUT]: {
      component: defineAsyncComponent(() => import('./error/General')),
      back: () => {
        close()
      },
      error: () => {},
      proceed: () => {},
      props: {
        type: 'loggedOut',
      },
    },

    [LOGIN_STEP.ERROR_DVP_UNSUPPORTED]: {
      component: defineAsyncComponent(() => import('./error/DVP_Unsupported')),
      back: () => {
        close()
      },
      error: () => {},
      proceed: () => {},
      props: {
        type: 'dvp_unsupported',
      },
    },
  }
})

// METHODS
/**
 * @note: is only used for the priceWidget, as we don't scroll to the entryWidget (and is not in use in dvp)
 */
function callerComponent() {
  return document.getElementById(`priceWidget_${localId.value}`)
}

function close() {
  isLoginModalOpen.value = false
  scrollToWidget()
}

async function createBackendBasket(__basket) {
  if (loginType.value === LOGIN_TYPE.KUPO) {
    return await AuthHelper.createBasket({ payload: __basket, platform: platform.value })
  } else {
    return await basketAPI.createBasketForPartnerNumber(
      {
        partnerNumber: partnerNumber.value,
        uid: uid.value,
      },
      __basket
    )
  }
}

async function createBasket() {
  let __basket = prepareBasket()

  loading$.start({ blocking: false })
  try {
    __basket = await createBackendBasket(__basket)

    await sessionStore.setSession(__basket)
    await basketStore.persistBasket(__basket)

    // do update-call to populate basket completely
    await basketStore.updateOvpBasket(__basket)

    events$.emit(EVENT_PERSONS_FORM_DISPLAYED, {
      source: channel.value,
      forced: true,
    })

    submitCallback.value()
    loading$.end()
    close()

    if (!hideNotification.value) {
      addToast({
        text: t('login.prompt.message', {
          firstName: headOfContract.value.personData.firstName,
          lastName: headOfContract.value.personData.lastName,
        }),
        type: NOTIFICATION.WARNING,
      })
    }
  } catch {
    setCurrent(LOGIN_STEP.ERROR_GENERAL)
    loading$.failed()
  }
}

function handleError(state) {
  switch (state) {
    case LOGIN_ERROR_STATE.DVP_UNSUPPORTED:
      setCurrent(LOGIN_STEP.ERROR_DVP_UNSUPPORTED)
      break
    case LOGIN_ERROR_STATE.LOCKED:
      setCurrent(LOGIN_STEP.ERROR_LOCKED)
      break
    case LOGIN_ERROR_STATE.LOGGED_OUT:
      setCurrent(LOGIN_STEP.ERROR_LOGGED_OUT)
      break
    case LOGIN_ERROR_STATE.GENERAL:
    default:
      setCurrent(LOGIN_STEP.ERROR_GENERAL)
  }
}

async function initModalAfterKupoLogin() {
  try {
    loading$.start()
    const __basket = await AuthHelper.getFamilyBasket(platform.value)
    loginType.value = LOGIN_TYPE.KUPO
    ReactivityUtil.reAssign(promptBasket, __basket)
    openLoginModal(LOGIN_STEP.SUCCESS)
    loading$.end()
  } catch (e) {
    loading$.end()
    // Handle error sets the currentId to the specific error screen

    // first, check, if we're on the correct campaign-page
    if (isOnVitalCampaignPageWhileLogin()) {
      handleError(LOGIN_ERROR_STATE.DVP_UNSUPPORTED)
    } else {
      handleError(e.message)
    }

    openLoginModal(currentId.value)
  }
}

function openLoginModal(startStep) {
  // reset/initialise state on opening the initial modal and surpess the refresh-event for modals
  reset(startStep, false)

  events$.emit(EVENT_LOGIN.OPENED, channel.value)

  // open the modal
  isLoginModalOpen.value = true
}

function prepareBasket() {
  const newBasket = { ...promptBasket, channel: channel.value }
  // immigrant needs always to be set to work for all cases with backend
  newBasket.persons = selectedPersons.map(p => {
    p.immigrant = false
    return p
  })
  if (email.value) newBasket.email = email.value
  if (mobile.value) newBasket.mobile = mobile.value
  // Overwrite lang with currently selected language
  newBasket.language = selectedLanguage.value.toUpperCase()
  return newBasket
}

function reset(step, triggerModalRefreshEvent = true) {
  const defaultStep = step || LOGIN_STEP.TYPE_SELECTION

  // reset state back to the initial-screen (type selection)
  setCurrent(defaultStep, triggerModalRefreshEvent)
}

function setCurrent(__currentId, triggerModalRefreshEvent = true) {
  currentId.value = __currentId

  if (triggerModalRefreshEvent) {
    events$.emit(EVENT_MODAL.UPDATE, {
      modalName: `loginModal--${__currentId}`,
    })
  }
}

function scrollToWidget() {
  const el = callerComponent()
  if (el) {
    el.scrollIntoView({ block: 'center', behavior: 'smooth' })
  }
}

// LIFECYCLE HOOKS
// eslint-disable-next-line sonarjs/cognitive-complexity
onMounted(() => {
  const loginStorage = localStorage.getItem(LOGIN_OPEN_MODAL)
  /*
   Remove open-login item from localStorage to only trigger modal once per login
   If there are multiple login-prompts on the screen, the componentId will make sure
   that the modal is opened from the correct prompt
   */
  if (loginStorage) {
    let componentId, startingChannel
    try {
      ;({ componentId, startingChannel } = JSON.parse(loginStorage))
    } catch {
      console.warn('Invalid localStorage value for login. Expected object, but got: ', loginStorage)
    }

    localId.value = componentId
    channel.value = startingChannel

    localStorage.removeItem(LOGIN_OPEN_MODAL)
    // Only open the modal if a user successfully logged in
    if (isExistingCustomerLoggedIn) {
      // TODO: Added a timeout because there's no handling of multiple loading-starts
      setTimeout(async () => {
        await initModalAfterKupoLogin()
      }, 500)
    }
  }

  events$.on(
    EVENT_LOGIN.OPEN,
    ({
      channel: __channel,
      step,
      platform: __platform,
      hideNotification: __hideNotification,
      submitCallback: __submitCallback,
      id,
    }) => {
      // set id; is used for the querySelector
      if (id) localId.value = id
      if (__channel) channel.value = __channel
      if (__hideNotification) hideNotification.value = __hideNotification
      if (__submitCallback) submitCallback.value = __submitCallback

      if (step === LOGIN_STEP.SUCCESS) {
        // We expect users to already be logged in at this point :/
        // but we have to set the login type manually and also load the family basket
        // the same way we do after manually logging in with Kupo
        platform.value = __platform
        initModalAfterKupoLogin()
      } else {
        openLoginModal(step)
      }
    }
  )
})

// EPILOGUE
defineExpose({ isLoginModalOpen })
</script>
