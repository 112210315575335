<template>
  <!-- prettier-ignore -->
  <div
    class="input"
    :class="classes"
  >
    <div class="input__elements">
      <form-dropdown
        ref="dialCode"
        class="input__dialcode"
        name="phone_code"
        :model-value="formData.dialCode"
        :options="countries"
        :readonly="pending"
        :required="isRequired"
        :theme="$props.theme"
        :title-label="$t('person.phonePrefix')"
        @update:model-value="setDialCode"
      />

      <form-input
        ref="number"
        class="input__number"
        autocomplete="tel"
        name="phone_number"
        :cy-test="formData.dialCode + formData.number"
        :model-value="formData.number"
        :readonly="pending"
        :required="isRequired"
        :theme="$props.theme"
        :title-label="$props.titleLabel"
        :type="browser.isDesktop ? 'text' : 'tel'"
        @blur="event => setNumber(event.target.value.trim(), true)"
        @update="input => setNumber(input)"
      >
        <slot />
      </form-input>

      <div
        v-show="isPending"
        class="input__loader"
      >
        <span v-text="'⚆'" />
      </div>
    </div>
    <the-typography
      class="input__hint"
      type="bodyLargeShort"
      v-text="autoHint"
    />
  </div>
</template>

<script setup>
import { computed, nextTick, reactive, watchEffect } from 'vue'

import debounce from 'lodash/debounce'

import { getCountries } from '@/utils/Enums'

import useBrowser from '@/hooks/useBrowser'
import useI18n from '@/hooks/useI18n'

import FormDropdown from '@/components/Form/Dropdown'
import FormInput from '@/components/Form/TextInput'

import { DEFAULT_DELAY_TIME } from '@/config/constants'

// HOOKS
const { browser } = useBrowser()
const { selectedLanguage, t } = useI18n()

// INIT
const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  defaultDialCode: {
    type: String,
    default: '+41',
  },
  delay: {
    type: Number,
    default: DEFAULT_DELAY_TIME * 1.5,
  },
  modelValue: {
    type: String,
    default: '',
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: 'light',
  },
  titleLabel: {
    type: String,
    default: '',
  },
  validate: {
    type: Object,
    default: () => {},
  },
})

// DATA
const formData = reactive({
  dialCode: props.defaultDialCode,
  number: null,
})
const update = debounce(emitUpdate, props.delay)

// COMPUTED
const autoHint = computed(() => {
  if (props.readonly) return

  let hints = []
  const errors = Array.from(props.validate?.$errors)

  let number = null
  switch (formData.dialCode) {
    case '+41':
      number = '+41 771002030'
      break

    case '+49':
      number = '+49 15210020030'
      break

    case '+39':
      number = '+39 3231002003'

      break
  }

  if (number) {
    hints.push(t('form.phoneHint', { number }))
  }

  if (invalid.value && errors.length > 0) {
    const error = errors[0]?.$validator
    hints.push(t(`form.error.${error}`))
  }
  return hints.join('; ')
})

const classes = computed(() => {
  return {
    'input--invalid': invalid.value,
    [`input--${props.theme}`]: true,
  }
})

const countries = computed(() => {
  return getCountries(selectedLanguage.value).map(country => {
    return {
      code: country.code,
      value: country.dialCode,
      text: `${country.text} (${country.dialCode})`,
      display: country.dialCode,
    }
  })
})

const invalid = computed(() => {
  return props.validate?.$invalid && props.validate?.$error
})

const isPending = computed(() => {
  return props.validate?.$pending || false
})

const isRequired = computed(() => {
  return props.required || props.validate?.required !== undefined
})

const pending = computed(() => {
  return (props.validate?.$pending || props.readonly) ?? false
})

// METHODS
function emitUpdate() {
  const { dialCode, number } = formData

  const newValue = `${dialCode}${number}`
  if (dialCode && number && props.modelValue !== newValue) {
    props.validate?.$touch()
    emit('update:modelValue', newValue)
  }
}

function getDialCode(input) {
  const country = countries.value.find(currentCountry => input.match(`^\\${currentCountry.value}`))
  if (country) {
    return country.value
  }
}

function setDialCode(input) {
  formData.dialCode = input
  update()
}

function setNumber(input, forceUpdate = false) {
  let number = input

  if (input[0] === '+') {
    const dialCode = getDialCode(input)
    if (dialCode) {
      number = number.substring(dialCode.length)
      setDialCode(dialCode)
    }
  }

  formData.number = number

  if (forceUpdate) {
    emitUpdate()
  } else {
    update()
  }
}

// WATCHERS
watchEffect(() => {
  const value = props.modelValue
  if (value) {
    nextTick(() => {
      setNumber(value)
    })
  }
})
</script>

<style name="mobile" scoped>
.input__elements {
  display: flex;
  flex-direction: row;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    bottom: 0;
    left: 0;

    background-color: var(--c-primary-neutral-1);
  }
}

.input__loader {
  position: absolute;
  top: 0;
  right: 0;
  height: 44px;
  width: 44px;
  font-size: 20px;
  line-height: 44px;
  text-align: center;
  cursor: wait;
  background-color: var(--c-primary-neutral-3);

  span {
    display: inline-block;
    animation: spin 2s linear infinite;
  }
}

.input--dark {
  .input__loader {
    background-color: var(--c-primary-neutral-2);
  }
}

.input__dialcode {
  flex: 0 1 85px;
  display: inline-block;
}

.input__number {
  flex: 1 1 auto;
}

.input__dialcode :deep(.dropdown__item),
.input__number :deep(.input__item) {
  border-bottom: 0;
}

.input__hint {
  color: var(--c-secondary-neutral-2);
  padding: 0 0 0 10px;

  transition: color 0.2s ease-in-out;
}

.input--invalid {
  .input__hint,
  & :deep(.input__title) {
    color: var(--c-secondary-color-1);
  }

  .input__elements {
    &:after {
      background-color: var(--c-secondary-color-1);
    }
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>

<style name="safari" scoped>
@media not all and (min-resolution: 0.001dpcm) {
  @media {
    .input__hint {
      margin-top: 0;
    }

    .input .input__number :deep(.input__item) {
      height: 45px;
    }
  }
}
</style>
