<template>
  <!-- prettier-ignore -->
  <transition name="fade">
    <div
      v-if="$props.showCard"
      class="card"
      :class="{'is-small-screen': poorPeopleFab}"
    >
      <template v-if="!contactInfo">
        <loading-alt/>
      </template>
      <template v-else>
        <the-typography
          class="card__title"
          tag="h3"
          type="heading04"
          v-text="$t('fab.title')"
        />
        <button
          v-if="!isDesktop || poorPeopleFab"
          class="card__button"
          @click="handleCardClose(false)"
        >
          <the-icon
            class="card__button-icon"
            art="light"
            name="xmark"
          />
        </button>

        <!-- ALVA -->
        <fab-entry
          v-if="hasAssistanceAndAlva"
          class="card__entry"
          :description="$t($props.entries.alva.description)"
          :links="$props.entries.alva.links"
          :title-label="$t($props.entries.alva.title)"
          @action="handleAction"
        />
        <div
          v-else-if="hasEntry(entryName.ALVA)"
          class="card__entry"
        >
          <div class="alva__text">
            <the-typography
              tag="span"
              type="bodyMediumLong"
              v-text="$t('fab.entries.alva.bubble')"
            />
          </div>
          <div class="alva__action">
            <Avatar small />
            <Form.Button
              class="alva__action__btn"
              primary
              tiny
              @click="handleAlvaClick"
              v-text="$t('fab.entries.alva.action')"
            />
          </div>
        </div>

        <!-- CONTACT -->
        <fab-contact
          class="card__entry"
          :description="$t(contactDescription)"
          :title-label="$t($props.entries.contact.title)"
          @action="handleAction"
        />

        <!-- APPOINTMENT -->
        <fab-entry
          v-if="hasEntry(entryName.APPOINTMENT)"
          class="card__entry"
          :description="$t($props.entries.appointment.description)"
          :links="$props.entries.appointment.links"
          :title-label="$t($props.entries.appointment.title)"
          @action="handleAction"
        />
      </template>
    </div>
  </transition>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'

import { events$ } from '@/services'

import useBrowser from '@/hooks/useBrowser'
import useContact from '@/hooks/useContact'
import useDocument from '@/hooks/useDocument'
import useI18n from '@/hooks/useI18n'

import * as Form from '@/components/Form'
import Avatar from '@/components/Aide/Avatar'
import FabContact from '@/components/Fab/FabContact'
import FabEntry from '@/components/Fab/FabEntry'
import LoadingAlt from '@/components/Container/LoadingAlt'
import { fabActions, fabEntryName } from '@/components/Fab/entries'

import { EVENT_APPOINTMENT_MODAL_OPEN, EVENT_CHAT } from '@/config/events'

// HOOKS
const { browser } = useBrowser()
const { contactInfo } = useContact()
const { setStaticBody, unsetStaticBody } = useDocument()
const { t } = useI18n()

// INIT
const emit = defineEmits('close')
const props = defineProps({
  entries: {
    type: Object,
    default: () => {},
  },
  showCard: {
    type: Boolean,
    default: false,
  },
})

// DATA
const entryName = ref(fabEntryName)
const poorPeopleFab = ref(false)

// COMPUTED
const contactDescription = computed(() => {
  return `${props.entries.contact.description}.${isAssistanceAvailable.value ? 'open' : 'closed'}`
})

const hasAssistanceAndAlva = computed(() => {
  return isAssistanceAvailable.value && hasEntry(entryName.value.ALVA)
})

const isAssistanceAvailable = computed(() => {
  return contactInfo.value.chat || contactInfo.value.phone
})

const isDesktop = computed(() => {
  const _isDesktop = browser.isDesktop
  if (props.showCard && !_isDesktop) {
    setStaticBody()
  } else {
    unsetStaticBody()
  }
  return _isDesktop
})

// METHODS
function handleAction(action) {
  if (action?.name === fabActions.OPEN_CHAT) {
    events$.emit(EVENT_CHAT.OPEN, { behavior: 'passive' })
  }

  if (action?.name === fabActions.CALENSO_ROUTE) {
    events$.emit(EVENT_APPOINTMENT_MODAL_OPEN, action.param)
  }

  handleCardClose(true)
}

function handleAlvaClick() {
  window.location = t(props.entries.alva.links[0].url)
  handleCardClose(true)
}

function handleCardClose(interacted) {
  unsetStaticBody()
  emit('close', interacted)
}

function handleResize() {
  poorPeopleFab.value = isSmallScreen()
}

function hasEntry(__entryName) {
  return !!props.entries[__entryName]
}

function isSmallScreen() {
  return window.innerHeight < 750
}

// WATCHERS
watch(
  () => props.showCard,
  value => {
    if (!value) {
      poorPeopleFab.value = false
    } else {
      if (!isDesktop.value || isSmallScreen()) {
        poorPeopleFab.value = true
        setStaticBody()
      }
    }
  },
  { immediate: true }
)

// LIFECYCLE HOOKS
onMounted(() => {
  window.addEventListener('resize', handleResize)
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})
</script>

<style name="animations" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>

<style name="mobile" scoped>
.card {
  font-family: 'Open Sans', sans-serif;
  position: fixed;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  z-index: 8998;
  padding: 70px 20px 25px;
  background-color: var(--c-primary-neutral-3);
  display: flex;
  flex-direction: column;
}

.card__title {
  margin-top: 0;
  margin-block-end: 1em;
}

.card__entry + .card__entry {
  margin-top: 15px;
  padding-top: 20px;
  border-top: 1px solid var(--c-secondary-neutral-3);
}

.card__button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: inherit;
  border: none;
  cursor: default;
  z-index: 8999;

  height: 42px;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
  }
}

.card__button-icon {
  font-size: 24px;
}

.alva__text {
  padding: 16px;
  background-color: var(--c-secondary-neutral-3);
}

.alva__action {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}

.alva__action:after {
  content: '';
  position: absolute;
  margin-left: 30px;
  margin-top: -15px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--c-secondary-neutral-3);
}

.alva__action__btn {
  width: inherit;
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .card:not(.is-small-screen) {
    right: 24px;
    bottom: 86px;
    width: 340px;
    height: auto;
    color: var(--c-primary-neutral-1);
    padding: 20px 20px 25px;
    border: 1px solid var(--c-secondary-neutral-3);
  }
}
</style>
