function enter(element) {
  const width = getComputedStyle(element).width

  element.style.width = width
  element.style.position = 'absolute'
  element.style.visibility = 'hidden'
  element.style.height = 'auto'

  const height = getComputedStyle(element).height

  element.style.width = null
  element.style.position = null
  element.style.visibility = null
  element.style.height = 0

  setTimeout(() => {
    element.style.height = height
  })
}

function afterEnter(element) {
  element.style.height = 'auto'
}

function leave(element) {
  const height = getComputedStyle(element).height

  element.style.height = height

  setTimeout(() => {
    element.style.height = 0
  })
}

export default function useHeightTransition () {
  return {
  	enter, afterEnter, leave
  }
}
