<template>
  <!-- prettier-ignore -->
  <div
    class="input"
    :class="classes"
  >
    <template v-if="isTestEnv">
      <the-message
        class="input__test-hint"
        severity="warning"
        text="OTP will be sent to the channels in Slack <a target='_blank' href='slack://channel?team=T19FP6GTF&id=C037W6U89B4'>#dev-sms-tan</a> and Teams <a target='_blank' href='https://teams.microsoft.com/l/channel/19%3AW7s2UcAhiC9M47OsiOaY8_MI2JNS-jaDGkxIjI4HGMs1%40thread.tacv2/dvp?groupId=0d0d8e1e-e52d-4c69-947f-6c46b871f87a&tenantId=a95da8fa-882c-4804-aeb4-81388d204ea8&ngc=true&allowXTenantAccess=true'>tokens</a>"
      />
    </template>
    <label>
      <the-typography
        class="input__title"
        type="bodyLargeShort"
        v-text="autoTitle"
      />
      <the-typography type="heading03">
        <div
          ref="codeElementsRef"
          class="input__elements"
        >
          <div
            v-for="(value, index) in values"
            :key="index"
            class="input__element"
          >
            <input
              :key="`code-${index}`"
              :ref="references[index]"
              v-bind="$attrs"
              autocomplete="off"
              maxlength="1"
              placeholder="0"
              :data-id="index"
              :pattern="$props.numeric ? '[0-9]' : null"
              :required="isRequired"
              :type="$props.numeric ? 'tel' : 'text'"
              :value="value"
              v-on="$attrs"
              @focus="$event.target.select()"
              @input="handleChange"
              @keyup.backspace.prevent="remove"
              @keyup.down.prevent
              @keyup.left.prevent="select(index - 1)"
              @keyup.right.prevent="select(index + 1)"
              @keyup.up.prevent
            />
          </div>
        </div>
      </the-typography>
      <the-typography
        class="input__hint"
        type="bodyLargeShort"
        v-text="autoHint"
      />
    </label>
    <slot />
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, reactive, ref } from 'vue'

import useI18n from '@/hooks/useI18n'

// HOOKS
const { t } = useI18n()

// INIT
const emit = defineEmits(['update:modelValue', 'completed'])
const props = defineProps({
  hint: {
    type: String,
    default: '',
  },
  length: {
    type: Number,
    default: 4,
  },
  modelValue: {
    type: [String, Number],
    default: undefined,
  },
  numeric: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: 'light',
  },
  titleLabel: {
    type: String,
    default: '',
  },
  validate: {
    type: Object,
    default: () => {},
  },
})
const isTestEnv = import.meta.env.VITE_ENV_KEY !== 'PRD'

// DATA
const codeElementsRef = ref(null)
const values = reactive([])

// COMPUTED
const autoTitle = computed(() => {
  return `${props.titleLabel} ${isRequired.value ? '*' : ''}`
})

const autoHint = computed(() => {
  const errors = Object.keys(props.validate?.$params ?? {}).filter(error => !props.validate[error])

  if (invalid.value && errors.length > 0) {
    return t(`form.error.${errors[0]}`)
  } else {
    return props.hint
  }
})

const classes = computed(() => {
  return {
    'input--invalid': invalid.value,
    [`input--${props.theme}`]: true,
  }
})

const invalid = computed(() => {
  return props.validate?.$invalid && props.validate?.$error
})

const isRequired = computed(() => {
  return props.required || props.validate?.required !== undefined
})

const references = computed(() => {
  const referencesArray = []
  for (let i = 0; i < props.length; i++) {
    referencesArray.push(`input_${i}`)
  }
  return referencesArray
})

// METHODS
function emitUpdate() {
  let code = values.join('')

  if (code === '') {
    code = null
  }

  emit('update:modelValue', code)

  if (code?.length >= props.length) {
    props.validate?.$touch()
    emit('completed', code)
  }
}

function handleChange($event) {
  if (props.numeric) {
    $event.target.value = $event.target.value.replace(/[^\d]/gi, '')
  }
  if ($event.target.value === '' || (props.numeric && !$event.target.validity.valid)) {
    return
  }

  let nextIndex
  const index = parseInt($event.target.dataset.id)
  const value = $event.target.value

  if (value.length <= 1) {
    nextIndex = index + 1
    values[index] = value
  } else {
    const split = value.split('')
    nextIndex = (nextIndex >= props.length ? props.length : value.length + index) - 1

    split.forEach((item, i) => {
      const cursor = index + i
      if (cursor < props.length) {
        values[cursor] = item
      }
    })
  }

  select(nextIndex)
  emitUpdate()
}

function initializeValues() {
  const code = props.modelValue?.toString()
  if (code?.length) {
    for (let i = 0; i < props.length; i++) {
      values.push(code[i] || null)
    }
  } else {
    Array(props.length)
      .fill(null)
      .forEach(item => {
        values.push(item)
      })
  }

  nextTick(() => select(0))
}

function remove($event) {
  const index = parseInt($event.target.dataset.id)
  const nextIndex = values[index] ? index : references.value[index - 1] ? index - 1 : null

  if (references.value[nextIndex]) {
    values[nextIndex] = null
    select(nextIndex)
    emitUpdate()
  }
}

function select(key) {
  if (codeElementsRef.value.children[key]) {
    const el = codeElementsRef.value.children[key].children[0]

    el.focus()
    el.select()

    // @todo: why is this reset needed?
    props.validate?.$reset()
  }
}

// LIFECYCLE HOOKS
onMounted(() => {
  initializeValues()
})
</script>

<style name="mobile" scoped>
.input {
  position: relative;
  width: 100%;
}

.input__test-hint {
  margin-bottom: 8px;
}

.input__title {
  color: var(--c-secondary-neutral-2);
  padding: 4px 10px;
  z-index: 1;
  cursor: text;
}

.input__element {
  display: inline-block;
  margin: 0 5px 0 0;
  cursor: text;
  border-bottom: 1px solid var(--c-primary-neutral-1);
  transition: border-bottom-color 0.2s ease-in-out;

  &:last-child {
    margin: 0;
  }
}

.input__element input {
  width: 50px;
  height: 50px;
  font-size: inherit;
  text-align: center;
  color: var(--c-primary-neutral-1);
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid transparent;
  transition: border-bottom 0.2s ease-in-out;

  &::-ms-clear {
    display: none;
  }

  &:focus {
    border-bottom: 1px solid var(--c-primary-neutral-1);
  }
}

.input__hint {
  color: var(--c-secondary-neutral-2);
  padding: 4px 10px;

  transition: color 0.2s ease-in-out;
}

.input--invalid {
  .input__element,
  .input__element input:focus {
    border-bottom-color: var(--c-secondary-color-1);
  }

  .input__title,
  .input__hint {
    color: var(--c-secondary-color-1);
  }
}

.input--dark {
  .input__element {
    background-color: var(--c-primary-neutral-2);
  }
}

.input--light {
  .input__element {
    background-color: var(--c-primary-neutral-3);
  }
}
</style>
