<template>
  <!-- prettier-ignore -->
  <div class="person-name">
    <div
      class="person-name__label"
      v-text="fullName"
    />
    <div
      v-if="!$props.noAge && $props.person.age > 0"
      class="person-name__age"
      v-text="ageText"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { DateTimeHelper } from '@/helpers/DateTime'

import useBrowser from '@/hooks/useBrowser'

// HOOKS
const { browser } = useBrowser()

// INIT
const props = defineProps({
  noAge: {
    type: Boolean,
    default: false,
  },
  person: {
    type: Object,
    required: true,
  },
})

// COMPUTED
const ageText = computed(() => {
  const birthdaySuffix = isBirthday.value && browser.isDesktop ? ' 🎂' : ''
  return `(${props.person.age}${birthdaySuffix})`
})

const fullName = computed(() => {
  if (props.person?.personData?.lastName) {
    return `${props.person.personData.firstName || props.person.firstName} ${props.person.personData.lastName}`
  }
  return props.person.firstName
})

const isBirthday = computed(() => {
  return DateTimeHelper.isBirthday(props.person.personData.dateOfBirth)
})
</script>

<style name="mobile" scoped>
.person-name {
  display: flex;
}

.person-name__label {
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
}

.person-name__age {
  min-width: 36px;
  padding-left: 4px;
  white-space: nowrap;
  align-self: flex-start;
  text-align: center;
}
</style>
