<template>
  <!-- prettier-ignore -->
  <the-modal
    v-model="modalActive"
    locked
    :modal-title="modalActive ? $t('screen.auth.title') : ''"
  >
    <div class="auth">
      <template v-if="requested">
        <the-typography
          class="auth__description"
          type="bodyLargeLong"
          v-text="$t('screen.auth.confirm.description')"
        />
        <Form.Form
          class="auth-form"
          autocomplete="off"
          name="auth"
          tracking-disabled
          :validator="v"
          @submit="submit"
        >
          <Form.CodeInput
            v-model="formData.tan"
            class="auth__input"
            autofocus
            mask="####"
            name="tan"
            numeric
            theme="dark"
            :delay="0"
            :title-label="$t('screen.reentry.insertTan')"
            :validate="v.tan"
          />
          <Form.Button
            primary
            type="submit"
            :text="$t('form.next')"
          />
          <Form.Button
            class="auth__button"
            plain
            tiny
            :disabled="smsDisabled"
            :text="$t('screen.auth.confirm.requestTAN')"
            @click="sendTAN"
          />
        </Form.Form>
      </template>
      <template v-else-if="isForcedLogout">
        <the-typography
          class="auth__description"
          type="bodyLargeLong"
          v-text="$t('screen.auth.forcedLogout.description')"
        />
        <Form.Button
          class="auth__button"
          primary
          :disabled="smsDisabled"
          :text="$t('screen.auth.forcedLogout.action')"
          @click="sendTAN"
        />
      </template>
      <template v-else>
        <the-typography
          class="auth__description"
          type="bodyLargeLong"
          v-text="$t('screen.auth.request.description')"
        />
        <the-typography
          class="auth__description"
          type="bodyLargeLong"
          v-text="$t('screen.auth.recipient', { phone })"
        />
        <Form.Button
          class="auth__button"
          primary
          :disabled="smsDisabled"
          :text="$t('screen.auth.request.requestTAN')"
          @click="sendTAN"
        />
      </template>
      <a
        class="auth__end"
        @click="endSession"
        v-text="endText"
      />
    </div>
  </the-modal>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'

import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import { loading$, events$ } from '@/services'

import basketAPI from '@/api/basket'
import basketStore from '@/store/basket'
import sessionStore from '@/store/session'
import useI18n from '@/hooks/useI18n'
import useStatus from '@/hooks/useStatus'
import useToast from '@/hooks/useToast'

import * as Form from '@/components/Form'

import { EVENT_BASKET, EVENT_OVP, EVENT_SESSION } from '@/config/events'
import { FORCED_LOGOUT_KEY, NOTIFICATION, ROUTE_NAME, SESSION_UPDATED_KEY, STORAGE_KEY } from '@/config/constants'

// HOOKS
const { t } = useI18n()
const { isOnboardingSecured } = useStatus()
const { addToast } = useToast()

// DATA
const checkerInterval = ref(undefined)
const formData = reactive({ tan: undefined, basketId: undefined, token: undefined })
const isForcedLogout = ref(false)
const modalActive = ref(false)
const requested = ref(false)
const smsDisabled = ref(false)

// COMPUTED
const endText = computed(() => {
  return isForcedLogout.value ? t('screen.auth.forcedLogout.end') : t('screen.auth.end')
})

const phone = computed(() => {
  if (!basketStore.basket.mobile) return

  // TODO get mobile
  const start = basketStore.basket.mobile.substring(0, 5)
  const inbetween = basketStore.basket.mobile.substring(5, basketStore.basket.mobile.length - 2).replace(/\d/g, '*')
  const end = basketStore.basket.mobile.substring(basketStore.basket.mobile.length - 2)

  return `${start}${inbetween}${end}`
})

// METHODS
function checkForcedLogout() {
  const token = window.localStorage.getItem(FORCED_LOGOUT_KEY)
  if (token) {
    window.localStorage.removeItem(FORCED_LOGOUT_KEY)
    isForcedLogout.value = true
    formData.token = token
    modalActive.value = true
  } else {
    isForcedLogout.value = false
  }
}

async function endSession() {
  await sessionStore.clearSession()

  window.location.reload()
}

function off() {
  events$.off(EVENT_SESSION.UPDATE, updateSession)
  stopTimer()
}

function reset() {
  if (basketStore.basket.submitted) {
    off()
  } else if (isOnboardingSecured.value) {
    // if onboarding-secured and basket not yet submitted, register timer
    updateCheckerInterval()
  } else {
    checkForcedLogout()
  }
}

function resetCheckerInterval() {
  clearInterval(checkerInterval.value)
  checkerInterval.value = null
}

async function sendTAN() {
  loading$.start()
  smsDisabled.value = true

  stopTimer()

  try {
    const { basketId } = await basketAPI.sendSMSTan(formData.token)
    formData.basketId = basketId
    loading$.end()
    requested.value = true
    addToast({
      text: t('screen.auth.sent'),
    })
    // 2 minutes wait time: prevent the user from to many retries
    window.setTimeout(() => (smsDisabled.value = false), 120000)
  } catch {
    loading$.failed()
    smsDisabled.value = false
  }
}

/**
 * show auth-form modal
 *
 * turn off listener
 * start timer for session-ender
 *
 */
function showAuthForm() {
  // Prevent the session to timeout while loader is showing. Reset timer in this case.
  if (loading$.getState().active.value) {
    return
  }

  // Prevent user from only reloading to gain session again
  window.localStorage.removeItem(STORAGE_KEY)
  formData.token = basketStore.basket.reentryToken
  modalActive.value = true
}

function stopTimer() {
  resetCheckerInterval()
}

/**
 * submit tan
 *
 * when response is submitted => redirect to nextSteps
 * when response is !submitted => turn on listener
 */
async function submit() {
  // clear timeouts
  stopTimer()

  try {
    const __basket = await basketStore.getBasket(formData)

    formData.tan = null
    formData.token = null
    formData.basketId = null
    requested.value = false
    v.value.$reset()

    if (__basket.submitted) {
      const router = useRouter()
      await router.push({ name: ROUTE_NAME.NEXT_STEPS })
    } else {
      reset()
    }

    modalActive.value = false
    // Needed because there is no reload to reactivate the sync
  } catch (error) {
    // for e.g. registerSync errors there is no infoMessage
    if (error.messages) {
      addToast({
        text: error.messages[0],
        type: NOTIFICATION.ERROR,
      })
    } else {
      console.error(error)
    }
    reset()
  }
}

function updateCheckerInterval() {
  checkerInterval.value =
    checkerInterval.value ||
    setInterval(() => {
      const sessionUpdated = parseInt(window.sessionStorage.getItem(SESSION_UPDATED_KEY), 10)
      if (!sessionUpdated) {
        resetCheckerInterval()
        return
      }
      const now = new Date().getTime()
      const VITE_SESSION_CLEAR = parseInt(import.meta.env.VITE_SESSION_CLEAR)
      const VITE_SESSION_AUTH = parseInt(import.meta.env.VITE_SESSION_AUTH)

      if (now - sessionUpdated >= VITE_SESSION_CLEAR) {
        window.localStorage.setItem(FORCED_LOGOUT_KEY, basketStore.basket.reentryToken)
        endSession()
      } else if (now - sessionUpdated >= VITE_SESSION_AUTH && !modalActive.value) {
        showAuthForm()
      }
    }, 1000)
}

function updateSession() {
  window.sessionStorage.setItem(SESSION_UPDATED_KEY, new Date().getTime().toString())
}

// VALIDATION
const v = useVuelidate(
  {
    tan: {
      required,
    },
  },
  formData,
  { $lazy: true }
)

// LIFECYCLE HOOKS
onMounted(() => {
  events$.on(EVENT_OVP.APPLICATION_SUBMITTED, () => {
    off()
  })
  events$.on(EVENT_BASKET.UPDATED, reset)
  events$.on(EVENT_SESSION.UPDATE, updateSession)
  reset()
})

onBeforeUnmount(() => {
  off()
  events$.off(EVENT_BASKET.UPDATED, reset)
})
</script>

<style name="mobile" scoped>
.auth {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  color: var(--c-primary-neutral-1);
  padding-bottom: 20px;

  button {
    min-width: 200px;
  }
}

.auth__input {
  margin-bottom: 20px;
}

.auth__button {
  color: var(--c-primary-color-3);
  display: block;
}

.auth__end {
  display: block;
}
</style>
