<template>
  <GMapMap
    ref="mapRef"
    class="mos-map"
    map-type-id="roadmap"
    :center="center"
    :options="googleOptions"
    :zoom="$props.zoom"
    @bounds_changed="handleBoundsEvent"
    @tilesloaded="handleTilesLoadedEvent"
    @zoom_changed="handleZoomEvent"
  >
    <GMapMarker
      v-if="showSanitasMarker"
      :id="sanitasMarker.id"
      mouseout="true"
      mouseover="true"
      :clickable="true"
      :draggable="false"
      :options="sanitasMarker.options"
      @mouseout="$emit('mouseout')"
      @mouseover="$emit('mouseover', sanitasMarker)"
    />

    <GMapPolygon
      :options="{ strokeColor: '#5BAC26', strokeWeight: 1, strokeOpacity: 1 }"
      :paths="switzerlandPolygon"
    />

    <GMapCluster
      v-if="!$props.isSearching && clusterMarker"
      :options="clusterMarker"
      :styles="clusterMarker.styles"
      :zoom-on-click="true"
      @click="$emit('cluster-click')"
    >
      <GMapMarker
        v-for="(marker, index) in markers"
        :key="index"
        :options="marker.options"
        @click="$emit('select', marker)"
        @mouseout="$emit('mouseout')"
        @mouseover="$emit('mouseover', marker)"
      >
        <GMapInfoWindow
          v-if="$props.hoverCard && $props.hoverCard.data.id === marker.id"
          :opened="Boolean($props.hoverCard)"
          :options="{
            pixelOffset: {
              height: -15,
            },
          }"
        >
          <info-card :options="$props.hoverCard.data" />
        </GMapInfoWindow>
      </GMapMarker>
    </GMapCluster>

    <GMapMarker
      v-if="selectedOffice"
      class="marker-selected"
      mouseout="true"
      mouseover="true"
      :clickable="true"
      :draggable="false"
      :options="selectedOffice.options"
      :position="selectedOffice.position"
      @click="$emit('select', selectedOffice)"
      @mouseout="$emit('mouseout')"
      @mouseover="$emit('mouseover', selectedOffice)"
    />
  </GMapMap>
</template>

<script setup>
import { computed, ref, watch } from 'vue'

import useBrowser from '@/hooks/useBrowser'

import InfoCard from '@/components/MedicalSearch/components/InfoCard'
import { googleDefaultOptions, ZOOM_DEFAULT, ZURICH_CENTER } from '@/components/MedicalSearch/config/constants'
import switzerlandPolygon from '@/components/MedicalSearch/data/switzerland.json'
import {
  centerLocationToSwissBounds,
  createPosition,
  createSwissBoundingBox,
  isWithinSwissBounds,
} from '@/components/MedicalSearch/utils/map'
import {
  createClusterMarker,
  createSanitasMarker,
  drawMarkers,
  findMarkerById,
} from '@/components/MedicalSearch/utils/marker'

// HOOKS
const { browser } = useBrowser()

// INIT
const emit = defineEmits(['cluster-click', 'loaded', 'mouseout', 'mouseover', 'reset', 'select', 'update', 'zoom'])
const props = defineProps({
  hovered: {
    type: String,
    default: '',
  },
  hoverCard: {
    type: Object,
    default: null,
  },
  isSearching: {
    type: Boolean,
    default: false,
  },
  location: {
    type: [Object, null],
    required: true,
  },
  results: {
    type: Array,
    default: () => [],
  },
  selected: {
    type: String,
    default: '',
  },
  zoom: {
    type: Number,
    default: ZOOM_DEFAULT,
  },
})

// DATA
const googleApi = ref('')
const mapBounds = ref(null)
const mapRef = ref(null)

// COMPUTED
const center = computed(() => {
  return props.location ? createPosition(props.location) : ZURICH_CENTER
})

const clusterMarker = computed(() => {
  return googleApi.value && createClusterMarker(googleApi.value)
})

const googleOptions = computed(() => {
  return {
    ...googleDefaultOptions,
    zoom: props.zoom,
    zoomControl: browser.isDesktop,
  }
})

const markers = computed(() =>
  googleApi.value ? drawMarkers(props.results, googleApi.value, props.hovered, props.selected) : []
)

const sanitasMarker = computed(() => {
  return googleApi.value && createSanitasMarker(googleApi.value)
})

const selectedOffice = computed(() => {
  return findMarkerById(markers.value, props.selected)
})

const showSanitasMarker = computed(() => {
  return sanitasMarker.value && props.zoom >= sanitasMarker.value.minZoom
})

// METHODS
/**
 * Gets triggered when bounds of maps are changing (e.g when user zooms or drags map)
 */
function handleBoundsEvent(bounds) {
  if (bounds !== undefined) {
    mapBounds.value = bounds
  }
}

/**
 * Gets triggered every time the map is updated (e.g. when user zooms, drags map)
 */
function handleTilesLoadedEvent() {
  if (isWithinSwissBounds(mapBounds.value)) {
    emit('update', mapBounds.value)
  } else {
    emit('reset')
    panToSwiss()
  }
}

/**
 * Handles zoom event on the map
 */
function handleZoomEvent(zoom) {
  emit('zoom', zoom)
}

function panToSwiss() {
  const isIE = !!document.documentMode
  const swissBounds = createSwissBoundingBox(googleApi.value)
  if (!isIE && swissBounds) {
    mapRef.value.panToBounds(swissBounds, 10)
  } else {
    const mapRefCenter = mapRef.value.getCenter()
    const currentLocation = centerLocationToSwissBounds(mapRefCenter)
    mapRef.value.panTo(currentLocation)
  }
  emit('update', mapBounds.value)
}

// WATCHERS
watch(mapRef, googleMap => {
  if (googleMap) {
    googleMap.$mapPromise.then(() => {
      mapRef.value?.panTo(center.value)
      googleApi.value = google.maps
      emit('loaded')
    })
  }
})
</script>

<style>
.mos-map {
  height: 100vh;

  div[title='marker-selected'],
  .marker-selected {
    opacity: 1 !important;
  }

  .cluster {
    div {
      top: 5px !important;
      left: -5px !important;
      text-align: center;
    }
  }

  &--failure {
    display: flex;
    > div {
      margin: auto 10px;
      width: 100vh;
    }
    h4 {
      color: var(--c-primary-neutral-3);
    }
    .loading {
      padding: 20px 0;
    }
  }
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c {
  background-color: var(--c-primary-neutral-1);
  border-radius: 0%;
}
.gm-style .gm-style-iw-tc::after {
  background-color: var(--c-primary-neutral-1);
  margin-left: -8px;
  height: 20px;
  width: 40px;
}
</style>
