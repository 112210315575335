import { EVENT_BASKET } from '@/config/events'
import { events$ } from '@/services'
import { BasketUtil } from '@/utils/Basket'

import basketStore from '@/store/basket'
import partnerStore from '@/store/partner'
import productStructureStore from '@/store/productStructure'

import useContract from '@/hooks/useContract'
import useIncentive from '@/hooks/useIncentive'
import usePerson from '@/hooks/usePerson'

export function registerUpdateBasketListeners() {
  // HOOKS
  const { installContractOnUpdate } = useContract()
  const { installIncentiveOnUpdate } = useIncentive()
  const { getPerson } = usePerson()

  function getPartnerPayload() {
    const contractOwner = getPerson(basketStore.basket.contractOwner?.personId)
    const customerNumber = contractOwner?.contractNumber
      ? BasketUtil.getCustomerNumberFromContract(contractOwner.contractNumber)
      : null

    return {
      contractStartDate: basketStore.basket.contractStartDate,
      language: basketStore.basket.language,
      distributionPartnerNumber: partnerStore.basketDistributionPartnerNumber.value,
      collectiveNumber: basketStore.basket.collective?.contractNumber,
      customerNumber,
    }
  }

  events$.once(EVENT_BASKET.CREATED, () => {
    partnerStore.install(getPartnerPayload())
  })

  events$.on(EVENT_BASKET.UPDATED, ({ basket, originalBasket }) => {
    partnerStore.installOnUpdate(getPartnerPayload(), originalBasket)

    productStructureStore.installProductStructureOnUpdate(basket, originalBasket)

    installContractOnUpdate(basket)
    installIncentiveOnUpdate(basket, originalBasket)
  })
}
