import { hasIn, isEmpty, isFunction, isObject } from 'lodash'

import { googleDefaultOptions, SWISS_BOUNDING_BOX, ZOOM_DEFAULT } from '@/components/MedicalSearch/config/constants'

export const centerLocationToSwissBounds = center => {
  let lat = center.lat()
  let lng = center.lng()

  if (lng > SWISS_BOUNDING_BOX.max.lng) {
    lng = SWISS_BOUNDING_BOX.max.lng
  }
  if (lng < SWISS_BOUNDING_BOX.min.lng) {
    lng = SWISS_BOUNDING_BOX.min.lng
  }
  if (lat > SWISS_BOUNDING_BOX.max.lat) {
    lat = SWISS_BOUNDING_BOX.max.lat
  }
  if (lat < SWISS_BOUNDING_BOX.min.lat) {
    lat = SWISS_BOUNDING_BOX.min.lat
  }

  return { lat, lng }
}

export const createBoundingBox = values => {
  const northEast = values.getNorthEast()
  const southWest = values.getSouthWest()

  return {
    min: { lat: southWest.lat(), lng: southWest.lng() },
    max: { lat: northEast.lat(), lng: northEast.lng() },
  }
}

export const createGoogleBounds = (googleApi, options) => {
  return new googleApi.LatLngBounds(options.min, options.max)
}

export const createPosition = position => {
  if (isObject(position)) {
    let lat = position.latitude || position.lat
    let lng = position.longitude || position.lng

    lat = isFunction(lat) ? lat() : parseFloat(lat)
    lng = isFunction(lng) ? lng() : parseFloat(lng)
    if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
      return { lat, lng }
    }
  }
}

export const createSwissBoundingBox = google => {
  return createGoogleBounds(google, SWISS_BOUNDING_BOX)
}

export const insideSwissBoundingBox = values => {
  let valid = false
  if (isPosition(values)) {
    const { lat, lng } = createPosition(values)
    valid =
      lng < SWISS_BOUNDING_BOX.max.lng &&
      lng > SWISS_BOUNDING_BOX.min.lng &&
      lat < SWISS_BOUNDING_BOX.max.lat &&
      lat > SWISS_BOUNDING_BOX.min.lat
  } else if (values) {
    const bounds = createBoundingBox(values)
    valid =
      bounds.max.lng < SWISS_BOUNDING_BOX.max.lng &&
      bounds.min.lng > SWISS_BOUNDING_BOX.min.lng &&
      bounds.max.lat < SWISS_BOUNDING_BOX.max.lat &&
      bounds.min.lat > SWISS_BOUNDING_BOX.min.lat
  }
  return valid
}

export const isBoundingBox = bounds => {
  const valid = bounds?.max?.lat && bounds?.max?.lng && bounds?.min?.lat && bounds?.min?.lng

  return !valid && !isEmpty(bounds) ? isGoogleBoundingBox(bounds) : valid
}

export const isGoogleBoundingBox = bounds => {
  const valid = bounds.getNorthEast && bounds.getSouthWest

  if (!isEmpty(bounds) && !valid && import.meta.env.DEV) {
    /* eslint-disable no-console */
    console.error('Bounding box is not googleMaps conform!', bounds, isEmpty(bounds))
    throw new Error('Bounding box is not googleMaps conform!')
  }

  return valid
}

/**
 * Returns true if zoom is equal to max defined zoom
 */
export const isMaxZoom = zoom => {
  if (!zoom) return false
  return zoom === googleDefaultOptions.maxZoom
}

const isPosition = position => {
  return (
    isObject(position) &&
    (hasIn(position, 'latitude') || hasIn(position, 'lat')) &&
    (hasIn(position, 'longitude') || hasIn(position, 'lng'))
  )
}

export const isWithinSwissBounds = bounds => {
  return isBoundingBox(bounds) && insideSwissBoundingBox(bounds)
}

/**
 * Sets zoom or if zoom is undefined is set to default zoom level
 */
export const setZoom = zoom => {
  return zoom || ZOOM_DEFAULT
}

/**
 * Increases zoom level according to gien zoom
 * @param zoom
 */
export const zoomIn = zoom => {
  if (zoom <= googleDefaultOptions.maxZoom) {
    return setZoom(zoom + 1)
  }
}
