import { debounce } from 'lodash'

let closeEvent = () => {}
let closeTimer = null
let currentEl = null

const closeOnScroll = () => closeEvent()

const startCloseTimer = () => {
  closeTimer = setTimeout(() => {
    closeEvent()
  }, 5000)
}

const stopCloseTimer = () => {
  clearTimeout(closeTimer)
}

const stopAllCloseTriggers = () => {
  stopCloseTimer()
  closeEvent = () => {}
}

const handleStickyOnDesktop = debounce(() => {
  const toggleEl = document.getElementById('basketWidgetToggle')
  if (toggleEl) {
    const boundings = toggleEl.getBoundingClientRect()
    return boundings.top + boundings.height <= 7 ? addStickyDesktopClass() : removeStickyDesktopClass()
  }
}, 5)

const handleStickyOnMobile = () => {
  window.scrollY > 0 ? addStickyMobileClass() : removeStickyMobileClass()
}

const addStickyMobileClass = () => {
  if (currentEl) {
    currentEl.classList.add('overlay__sticky')
  }
}

const addStickyDesktopClass = () => {
  if (currentEl) {
    currentEl.classList.add('overlay__sticky-desktop')
  }
}

const removeStickyMobileClass = () => {
  if (currentEl) {
    currentEl.classList.remove('overlay__sticky')
  }
}

const removeStickyDesktopClass = () => {
  if (currentEl) {
    currentEl.classList.remove('overlay__sticky-desktop')
  }
}

const initAutoOpenedListeners = el => {
  window.addEventListener('scroll', closeOnScroll)
  el.addEventListener('mouseenter', stopCloseTimer)
  el.addEventListener('mouseleave', startCloseTimer)
  startCloseTimer()
}

const removeAutoOpenedListeners = el => {
  window.removeEventListener('scroll', closeOnScroll)
  el.removeEventListener('mouseenter', stopCloseTimer)
  el.removeEventListener('mouseleave', startCloseTimer)
  stopCloseTimer()
}

const initMobileListeners = el => {
  window.addEventListener('scroll', handleStickyOnMobile)
  const toggleEl = el.querySelector('.extend-toggle')
  if (toggleEl) {
    toggleEl.addEventListener('click', stopAllCloseTriggers)
  }
}

const removeMobileListeners = el => {
  window.removeEventListener('scroll', handleStickyOnMobile)
  const toggleEl = el.querySelector('.extend-toggle')
  if (toggleEl) {
    toggleEl.removeEventListener('click', stopAllCloseTriggers)
  }
}

const initDesktopListeners = () => {
  window.addEventListener('scroll', handleStickyOnDesktop)
}

const removeDesktopListeners = () => {
  window.removeEventListener('scroll', handleStickyOnDesktop)
}

const stickyBasketDirective = {
  beforeMount: function (el, binding, vnode) {
    const { isManuallyOpened, onClose } = binding.value
    const isDesktop = vnode.props['sticky-basket-desktop']

    closeEvent = onClose
    currentEl = el

    if (!isManuallyOpened) {
      initAutoOpenedListeners(el)
    }
    if (isDesktop) {
      initDesktopListeners()
      handleStickyOnDesktop()
    } else {
      initMobileListeners(el)
      handleStickyOnMobile()
    }
  },

  updated: function (el, binding, vnode, oldVnode) {
    const oldIsDesktop = oldVnode.props['sticky-basket-desktop']
    const isDesktop = vnode.props['sticky-basket-desktop']
    const isResize = oldIsDesktop !== isDesktop

    const { isManuallyOpened } = binding.value

    if (isManuallyOpened) {
      removeAutoOpenedListeners(el)
    }

    if (isDesktop) {
      handleStickyOnDesktop()
      if (isResize) {
        removeMobileListeners(el)
        initDesktopListeners()
      }
    } else {
      handleStickyOnMobile()
      if (isResize) {
        removeDesktopListeners()
        initMobileListeners(el)
      }
    }
  },

  unmounted: function (el, _binding, _vnode) {
    removeAutoOpenedListeners(el)
    removeDesktopListeners()
    removeMobileListeners(el)
  },
}

export default stickyBasketDirective
